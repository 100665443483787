import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Row } from "react-bootstrap";
//import {formatNumber} from "../Auxiliares/ConvertNumber";
import { formatNumber, formatPorcentajeDoubleEs} from '../Auxiliares/ConvertNumber';

const CasillasReportadasInstaladas = props => {

var info = props.data.casillas;

  return (
    <div className="graficasCasInst contenido borde">
      <p className="preludio mb-4">De las <strong>{formatNumber(info.totalCasillasPrimRepConc)}</strong> casillas con primer reporte concluido:</p>
      <Row>
        <div className="reordenarCasInst">  
          <p>
            <span className="enfasis2">{formatNumber(info.totalMDCCompleta)}</span> casillas se instalaron con Mesa Directiva de Casilla completa
          </p>       
        </div>
        <div className="barraProgressCasInst">
            <ProgressBar now={info.porcentajeMDCCompleta}/>
        </div>
        <div className="porciento">
            <p className="enfasis1">
              {formatPorcentajeDoubleEs(info.porcentajeMDCCompleta)}%
            </p>
        </div>
      </Row>

      <Row>
        <div className="reordenarCasInst">  
          <p>
            <span className="enfasis2">{formatNumber(info.totalFuncionariosFila)}</span> casillas se instalaron con algún Funcionario de Casilla tomado de la fila
          </p>       
        </div>
        <div className="barraProgressCasInst">
          <ProgressBar now={info.porcentajeFuncionariosFila}/>
        </div>
        <div className="porciento">
          <p className="enfasis1">
            {formatPorcentajeDoubleEs(info.porcentajeFuncionariosFila)}%
          </p>
        </div>
      </Row>

      <Row>
        <div className="reordenarCasInst">  
          <p>
          <span className="enfasis2">{formatNumber(info.totalRepresentantes)}</span> casillas tienen presencia de Representantes de PP / CI
          </p>       
        </div>
        <div className="barraProgressCasInst">
          <ProgressBar now={info.porcentajeRepresentantes}/>
        </div>
        <div className="porciento">
          <p className="enfasis1">
            {formatPorcentajeDoubleEs(info.porcentajeRepresentantes)}%
          </p>
        </div>
      </Row>
    </div>
  );
};



export default CasillasReportadasInstaladas;

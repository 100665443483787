import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const GraficoLinea = props => {

  const defaultOptions = {
    
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        format: '{value}%'
      },
      tickInterval: 10,
      min: 0,
      max: 100,
    },
    series: [{
      name: "Casillas instaladas",
      marker: {
        fillColor: '#BF6DA0',
        radius: 7,
        states: {
          hover: {
            fillColor: '#D4007F',
            lineWidthPlus: 0,
            radiusPlus: 4,
          }
        }
      },
      color: '#363642',
    }],
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return this.x + ' - ' + this.y + '% <br/>' + this.series.name;
      }
    },
  };
  const chartOptions = {
    ...defaultOptions,
    ...props.conf
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  )
}

export default GraficoLinea;

import React from "react";
import { Modal } from "react-bootstrap";
import { EtiquetasGenerales } from "../../Constants/ConstantesGlobales";
import nl2br from "react-newline-to-break";

const ModalInformativo = props => {

    const { mensaje } = props;

    return (
        <Modal {...props}>
            <Modal.Header closeButton>
                <Modal.Title>Acerca del cálculo</Modal.Title>
            </Modal.Header>
            <Modal.Body>{nl2br(mensaje)}</Modal.Body>
        </Modal>
    );
};

export default ModalInformativo;

const ColoresIncidentes = [
    {
      from: 0,
      to: 0.99,
      color: "#FFFFFF",
      name: "0"
    },
    {
      from: 1,
      to: 5.99,
      color: "#FFE6F7",
      name: "1 - 5"
    },
    {
      from: 6,
      to: 10.99,
      color: "#EFB9E0",
      name: "6 - 10"
    },
    {
      from: 11,
      to: 20.99,
      color: "#DA91E2",
      name: "11 - 20"
    },
    {
      from: 21,
      to: 10000,
      color: "#D4007F",
      name: "21 - o más"
    }
  ];
  
  export default ColoresIncidentes;
  
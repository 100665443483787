import React from "react";
import { Alert } from "react-bootstrap";

const alertDefaultOpts = {
  className: "texto-centrado"
};

export const AlertaCatalogo = props => {
  const mergeProps = { variant: "danger", ...alertDefaultOpts, ...props };

  return (
    <Alert {...mergeProps}>
      El recurso <b>Cátalogo de Estados</b> no se encuentra.
    </Alert>
  );
}

export const AlertaPantalla = props => {
  const mergeProps = { variant: "danger", ...alertDefaultOpts, ...props };

  return (
    <Alert {...mergeProps}>
      No se encuentra el recurso para configurar la pantalla.
    </Alert>
  );
}

export const AlertaGeneralElemento = props => {
  const mergeProps = { variant: "warning", ...alertDefaultOpts, ...props };

  return (
    <Alert {...mergeProps}>
      No se encuentra el recurso para mostrar.
    </Alert>
  );
}
import React, { Component } from "react";

//Plugins
import { Container, Row, Col, Image } from "react-bootstrap";
//Constants
import { EtiquetasHeader } from "../../Constants/ConstantesGlobales";
//Imagenes
import logoIne from "../../Assets/images/logo_INE.png";
import logoSIJE from "../../Assets/images/logo_SIJE.svg";

class Header extends Component {
  render() {
    return (
      <div className="encabezado">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={10} lg={10} xl={10}>
              <div className="imagenUno">
                <a
                  href="http://www.ine.mx/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ocultarMovil"
                >
                  <Image src={logoIne} className="logoINE" />
                </a>
                <a
                  href="/"
                  className="ocultarDesktop"
                >
                  <Image src={logoSIJE} className="logoINE" />
                </a>
              </div>
              <div>
                <h1 className="tituloSitio">
                  {EtiquetasHeader.titulo_sistema}
                </h1>
                <h1 className="subTituloSitio">
                  {EtiquetasHeader.titutlo_sub_sistema}
                </h1>
              </div>
            </Col>
            <Col xs={2} md={2} className="ocultarMovil">
              <a href="/">
              <div className="text-right">
                <Image src={logoSIJE} className="logoSIJE" />
              </div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Header;

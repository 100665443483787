import React, { Component } from "react";
import DatosInformativos from "../../Components/Genericos/DatosInformativos";
import Botones from "../../Components/Genericos/Botones";
//Plugins
import { Container, Row, Col, Form } from "react-bootstrap";
import queryString from "query-string";
//Constants
import {
  EtiquetasBotones,
  EtiquetasSubEncabezado
} from "../../Constants/ConstantesGlobales";
//JsonMenu
import jsonMenu from "../../Assets/json/Menu/Menu.json";
import Encabezado from "../../Components/Genericos/Encabezado";
import SubEncabezado from "../../Components/Genericos/SubEncabezado";

import GraficaCasillasInstaladas from "../../Components/ResumenGeneral/GraficaCasillasInstaladas";
import { formatNumber} from "../../Components/Auxiliares/ConvertNumber";
import GraficaCasillasIntaladasPie from "../../Components/ResumenGeneral/GraficaCasillasInstaladasPie";
import Cargando from "../../Components/Genericos/Cargando";

import { EtiquetasGenerales, EtiquetasResumenGeneral, EtiquetasIntalacionCasillas } from "../../Constants/ConstantesGlobales";

//////////// CORS ////////////

var myHeaders = new Headers();

myHeaders.append("X-Content-Type-Options", "nosniff");
myHeaders.append("X-Frame-Options", "deny");
myHeaders.append("X-XSS-Protection", "1;mode=block");
myHeaders.append("Cache-Control", "no-cache, no-store, must-revalidate");

myHeaders.append("Content-Type", "text/plain");
myHeaders.append(
  "Access-Control-Allow-Headers",
  "Origin, X-Requested-With, Content-Type, Accept"
);

var headers = {
  method: "GET",
  headers: myHeaders
};

//////////////////////////////

class ResumenGeneralEntidad extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
      estados: null,
      origenDatos: null,
      descripcionSeccion: null,
      descripcionNivel: null,
      path: null,
      showModal: false,
      numEntidad: null,
      arrayCasillasInstaladas: [],
      arrayCasillasPrimerReporteConcluido: [],
      arrayCasillasSegundoReporte: []
    };
  }

  componentDidMount() {
    var path = this.props.location.pathname;
    var arreglo = path.split("/");
    var nivel = arreglo[arreglo.length - 1];
    var seccion = arreglo[arreglo.length - 2];

    var palabra = jsonMenu.secciones.filter(
      secciones => secciones.idSeccion === Number(seccion)
    );
    var aux = palabra[0].nivelNavegacion;

    var descripcionSeccion = palabra[0].descripcionSeccion;
    var descripcionNivel = aux[nivel - 1].descripcionNivel;
    var origenDatos = aux[nivel - 1].origenDatos;

    this.setState({
      descripcionSeccion: descripcionSeccion,
      descripcionNivel: descripcionNivel,
      origenDatos: origenDatos,
      path: path
    });

    const idEntidad = queryString.parse(this.props.location.search);
    var numEntidad = idEntidad.idEntidad;

    if (numEntidad == null) {
      numEntidad = 1;
    }
    this.setState({ numEntidad: numEntidad });
    this.actualizarJson(numEntidad);

    let ruta2 = "/json/Catalogos/CatalogoEstados.json";
    fetch(ruta2, headers)
      .then(resolve => {
        return resolve.json();
      })
      .then(info => {
        this.setState({ estados: info });
      })
      .catch(error => {
        console.log("El error es : ", error);
      });
  }

  componentDidUpdate(prevProps) {
    // Sólo para cargar la información cuando hace el corte.
  }

  actualizarJson(idEstado) {
    const arrayCasillasIns = [];
    const arrayCasillasPrimRepConc = [];
    const arrayCasillasSegRep = [];
    let id = idEstado;
    console.info("ID ESTADO: " + id);
    let path =
      "/json/Secciones/ResumenGeneral/Entidad/ResumenGeneralEntidad_" +
      id +
      ".json";
    let ruta = path;
    fetch(ruta, headers)
      .then(resolve => {
        return resolve.json();
      })
      .then(info => {
        this.setState({ data: info })

        info.grafica.map((infoGrafica, index) => {
          if (infoGrafica.nombre === 'Casillas instaladas') {
            arrayCasillasIns.push(infoGrafica)
          } else if (infoGrafica.nombre === 'Casillas con primer reporte concluido') {
            arrayCasillasPrimRepConc.push(infoGrafica);
          } else if (infoGrafica.nombre === 'Casillas con segundo reporte') {
            arrayCasillasSegRep.push(infoGrafica);
          }
        })

        this.setState({
          arrayCasillasInstaladas: arrayCasillasIns,
          arrayCasillasPrimerReporteConcluido: arrayCasillasPrimRepConc,
          arrayCasillasSegundoReporte: arrayCasillasSegRep
        })

      })
      .catch(error => {
        console.log("El error es : ", error);
      });
  }

  handleChange = event => {
    this.props.history.push(
      "/ResumenGeneralEntidad/1/2?idEntidad=" + event.target.value
    );
    let fleldVal = event.target.value;
    this.actualizarJson(fleldVal);
  };

  render() {
    if (!this.state.data || !this.state.origenDatos) {
      return (
        <div>
          <Cargando />
        </div>
      );
    }

    if (
      !this.state.descripcionNivel ||
      !this.state.descripcionSeccion ||
      !this.state.estados ||
      !this.state.numEntidad
    ) {
      return (
        <div>
          <Cargando />
        </div>
      );
    }

    return (
      <div>
        {/* Datos Informativos */}
        <Container>
          <Row>
            <Col xs={8} xl={12} sm={8} md={12} lg={12}>
              <DatosInformativos
                data={this.state.data}
                path={this.state.path}
                nivel={this.state.descripcionSeccion}
              />
            </Col>
            <Col xs={4} sm={4} className="ocultarDesktop">
              <Botones
                tipoBoton="1"
                etiqueta={EtiquetasBotones.etiqueta_boton_actualizar}
                path={this.state.path}
              />
            </Col>
          </Row>
          {/* Titulo de la página y botones de actualizacion y descarga */}
          <Row>
            <Col xs={12} xl={8} sm={12} md={8} lg={8}>
              <Encabezado
                descripcionSeccion={this.state.descripcionSeccion}
                descripcionNivel={this.state.descripcionNivel}
              />
            </Col>
            <Col xs={4} xl={4} sm={4} md={4} lg={4} className="ocultarMovil">
              <Botones
                tipoBoton="1"
                etiqueta={EtiquetasBotones.etiqueta_boton_actualizar}
                path={this.state.path}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} xl={6} sm={12} md={6} lg={6}>
              <Form>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    as="select"
                    defaultValue={this.state.numEntidad}
                    onChange={this.handleChange.bind(this)}
                  >
                    {this.state.estados.estados.map((info, index) => (
                      <option key={index} value={info.idEstado}>
                        {info.nombreEstado}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Col>
          </Row>

          <Row className="mb-4 ocultarMovil">
            <Col xs={9} xl={9} sm={9} md={9} lg={9}>
              <SubEncabezado
                mensaje={
                  EtiquetasSubEncabezado.etiqueta_subencabezado_general_entidad
                }
              />
            </Col>
            <Col xs={3} xl={3} sm={3} md={3} lg={3}>
              <Botones
                tipoBoton="2"
                etiqueta={EtiquetasBotones.etiqueta_boton_base_datos}
                path={this.state.path}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} xl={12} sm={12} md={12} lg={12} className="texto-centrado borde-gris">
            <span className="etiqueta1">{EtiquetasGenerales.etiqueta_general_casillas_aprobadas} </span> 
            <span className="enfasis2">{formatNumber(this.state.data.tnAprobadas.totalCasillasAprobadas)}</span>
            </Col>
          </Row>

          <Row>
           {/* casillas instaladas */}
           <Col xs={4} xl={4} sm={4} md={4} lg={4} className="ajusteMiniGrafica">
              <GraficaCasillasInstaladas
                data={this.state.data}
                path={this.state.path}
                totalCasillas={this.state.data.instalacion.totalCasillasInstaladas}
                dataGrafica={this.state.arrayCasillasInstaladas}
                descripcionEjeY={EtiquetasResumenGeneral.etiqueta_descripcion_ejey_grafica_general}
                labelSuperior={EtiquetasGenerales.etiqueta_general_casillas_reportadas_instaladas}
                mensajeModal={EtiquetasResumenGeneral.etiqueta_modal_informativo_grafica_casillas_instaladas}
                casillasInferior={this.state.data.instalacion.totalCasillasReportadas}
                labelInferior={EtiquetasGenerales.etiqueta_general_casillas_reportadas}
              />
            </Col>

            {/* casillas con primer reporte concluido */}
            <Col xs={4} xl={4} sm={4} md={4} lg={4}>
              <GraficaCasillasInstaladas
                data={this.state.data}
                path={this.state.path}
                totalCasillas={this.state.data.instalacion.totalCasillasPrimRepConc}
                dataGrafica={this.state.arrayCasillasPrimerReporteConcluido}
                descripcionEjeY={EtiquetasResumenGeneral.etiqueta_descripcion_ejey_grafica_general}
                labelSuperior={EtiquetasGenerales.etiqueta_general_casillas_con_primer_reporte_concluido}
                mensajeModal={EtiquetasResumenGeneral.etiqueta_modal_informativo_grafica_casillas_primer_rep_concluido}
                casillasInferior={this.state.data.instalacion.totalCasillasInstaladas}
                labelInferior={EtiquetasGenerales.etiqueta_general_casillas_reportadas_instaladas}
              />
            </Col>

            {/* casillas con segundo reporte */}
            <Col xs={4} xl={4} sm={4} md={4} lg={4}>
              <GraficaCasillasInstaladas
                data={this.state.data}
                path={this.state.path}
                totalCasillas={this.state.data.segundo.totalCasillasSegundo}
                dataGrafica={this.state.arrayCasillasSegundoReporte}
                descripcionEjeY={EtiquetasResumenGeneral.etiqueta_descripcion_ejey_grafica_general}
                labelSuperior={EtiquetasGenerales.etiqueta_general_casillas_segundo_reporte}
                mensajeModal={EtiquetasResumenGeneral.etiqueta_modal_informativo_grafica_casillas_segundo_reporte}
                casillasInferior={this.state.data.instalacion.totalCasillasPrimRepConc}
                labelInferior={EtiquetasGenerales.etiqueta_general_casillas_con_primer_reporte_concluido}
              />
            </Col>
            {/* Grafica de pie de casillas instaladas */}
            <Col xs={12} xl={12} sm={12} md={12} lg={12}>
              <GraficaCasillasIntaladasPie
                data={this.state.data}
                path={this.state.path}
              />
            </Col>
          </Row>

          {/* Incidentes 
          <Row>
            <IncidentesMasReportados data={this.state.data} />
          </Row>
          */}
        </Container>
      </div>
    );
  }
}

export default ResumenGeneralEntidad;

import React from 'react';
import { ProgressBar } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import { formatNumber, formatPorcentajeDoubleEs } from "../Auxiliares/ConvertNumber";
import { EtSegRep } from "../../Constants/ConstantesGlobales";

const MesasDirectivas = props => {
    const mesasDirectivas = props.data;
    const ordenIntegracion = ["Propietario", "Suplente general", "Tomado de la fila", "Sin funcionario"];
    const listaFuncionarios = Object.keys(mesasDirectivas.funcionarios).sort();

    return (
        <div className="contenido borde mb-4">
            <p className="preludio mb-4">
                La integración de las Mesas Directivas de Casilla de
                las <strong>{formatNumber(mesasDirectivas.totalCasillasSegundoReporte)}</strong> casillas
                con Segundo reporte es:
            </p>

            <Row>
                {listaFuncionarios.map((funcionario) => (
                    <Col key={funcionario} xs={12} xl={6} sm={12} md={12} lg={6} className="funcionariosCont">
                        {mesasDirectivas.funcionarios[funcionario] &&
                            <>
                                <Row className="lineaMedia">
                                    <h2>{EtSegRep[funcionario]}</h2>
                                </Row>
                                {ordenIntegracion.map((nombre) =>
                                    mesasDirectivas.funcionarios[funcionario].map((info, index) => (
                                        nombre === info.nombre && <Row key={index}>
                                            <Col key={funcionario + index} xs={6} xl={6} sm={6} md={6} lg={6}>
                                                <div className="nameFunc">
                                                    <p>
                                                        {info.nombre}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="enfasis2">{formatPorcentajeDoubleEs(info.porcentaje)}%</p>
                                                    <p><strong>{formatNumber(info.total)}</strong></p>
                                                </div>
                                            </Col>
                                            <Col xs={6} xl={6} sm={6} md={6} lg={6} className="barrasFunc">
                                                {generarProgress(info, index)}
                                            </Col>
                                        </Row>
                                    ))
                                )}
                            </>
                        }
                    </Col>
                ))}
            </Row>
        </div>
    );
}

const generarProgress = (props, index) => {
    return <ProgressBar key={index} now={props.porcentaje} />;
};

export default MesasDirectivas;
import React, { Component } from "react";
import DatosInformativos from "../../Components/Genericos/DatosInformativos";
import Botones from "../../Components/Genericos/Botones";
//Plugins
import { Container, Row, Col } from "react-bootstrap";
//Constants
import {
  EtiquetasBotones,
  EtiquetasSubEncabezado,
  EtiquetasIntalacionCasillas
} from "../../Constants/ConstantesGlobales";
//JsonMenu
import jsonMenu from "../../Assets/json/Menu/Menu.json";
import Encabezado from "../../Components/Genericos/Encabezado";
import SubEncabezado from "../../Components/Genericos/SubEncabezado";

import MapaNacional from "../../Components/Genericos/MapaNacional";

import TotalCasillasAprobadas from "../../Components/InstalacionCasillas/TotalCasillasAprobadas";
import CasillasReportadasInstaladas from "../../Components/InstalacionCasillas/CasillasReportadasInstaladas";
//import Caes from "../../Components/InstalacionCasillas/Caes";
import TipoAplicativo from "../../Components/InstalacionCasillas/TipoAplicativo";
import ModalInformativo from "../../Components/Genericos/ModalInformativo";
import Cargando from "../../Components/Genericos/Cargando";

class InstalacionCasillasNacional extends Component {
  constructor() {
    super();

    this.state = {
      data: null,
      estados: null,
      origenDatos: null,
      descripcionSeccion: null,
      descripcionNivel: null,
      path: null,
      showModal: false,
      titulo: 'Reportes de instalación de casillas por tipo de aplicativo'  
    };
  }

  componentDidMount() {
    var path = this.props.location.pathname;
    var arreglo = path.split("/");
    var nivel = arreglo[arreglo.length - 1];
    var seccion = arreglo[arreglo.length - 2];

    var palabra = jsonMenu.secciones.filter(
      secciones => secciones.idSeccion === Number(seccion)
    );
    var aux = palabra[0].nivelNavegacion;

    var descripcionSeccion = palabra[0].descripcionSeccion;
    var descripcionNivel = aux[nivel - 1].descripcionNivel;
    var origenDatos = aux[nivel - 1].origenDatos;

    this.setState({
      descripcionSeccion: descripcionSeccion,
      descripcionNivel: descripcionNivel,
      origenDatos: origenDatos,
      path: path
    });

     //////////// CORS ////////////

     var myHeaders = new Headers();
     
     myHeaders.append("X-Content-Type-Options", "nosniff");
     myHeaders.append("X-Frame-Options", "deny");
     myHeaders.append("X-XSS-Protection", "1;mode=block");
     myHeaders.append("Cache-Control", "no-cache, no-store, must-revalidate");
     
     myHeaders.append("Content-Type", "text/plain");
     myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
     
     var headers = { 
     method: 'GET',
     headers: myHeaders
    };

    //////////////////////////////

    fetch(origenDatos, headers)
      .then(resolve => {
        return resolve.json();
      })
      .then(info => {
        this.setState({ data: info });
      })
      .catch(error => {
        console.log("El error es : ", error);
      });
  }

  componentDidUpdate(prevProps) {
    // Sólo para cargar la información cuando hace el corte.
  }

  render() {
    if (!this.state.data || !this.state.origenDatos) {
      return <div><Cargando /></div>;
    }

    if (
      !this.state.descripcionNivel ||
      !this.state.descripcionSeccion
    ) {
      return <div>Loading Levels...</div>;
    }

    return (
      <div>
        {/* Datos Informativos */}
        <Container>
          <Row>
            <Col xs={8} xl={12} sm={8} md={12} lg={12}>
              <DatosInformativos
                data={this.state.data}
                path={this.state.path}
              />
            </Col>
            <Col xs={4} sm={4} className="ocultarDesktop">
              <Botones
                tipoBoton="1"
                etiqueta={EtiquetasBotones.etiqueta_boton_actualizar}
                path={this.state.path}
              />
            </Col>
          </Row>
          {/* Titulo de la página y botones de actualizacion y descarga */}
          <Row>
            <Col xs={12} xl={8} sm={12} md={8} lg={8}>
              <Encabezado
                descripcionSeccion={this.state.descripcionSeccion}
                descripcionNivel={this.state.descripcionNivel}
              />
            </Col>
            <Col xs={4} xl={4} sm={4} md={4} lg={4} className="ocultarMovil">
              <Botones
                tipoBoton="1"
                etiqueta={EtiquetasBotones.etiqueta_boton_actualizar}
                path={this.state.path}
              />
            </Col>
          </Row>

          <Row className="mb-2 ocultarMovil">
            <Col xs={12} xl={12} sm={12} md={12} lg={12}>
              <SubEncabezado
                mensaje={
                  EtiquetasSubEncabezado.etiqueta_subencabezado_instalacion_nacional
                }
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} xl={9} sm={12} md={9} lg={9}>
              <h2>
                {
                  EtiquetasIntalacionCasillas.etiqueta_instalacion_Casillas_instaladas
                }
                <span
                  onClick={() => this.openModal()}
                  className="quitarSubrayado"
                >
                  <i className="fas fa-question-circle info"></i>
                </span>
              </h2>
            </Col>
            <Col xs={3} xl={3} sm={3} md={3} lg={3} className="ocultarMovil">
              <Botones
                tipoBoton="2"
                etiqueta={EtiquetasBotones.etiqueta_boton_base_datos}
                path={this.state.path}
              />
            </Col>
          </Row>

          <div className="mb-2"></div>

         <Row>
            <Col xs={12} xl={8} sm={12} md={12} lg={8}>
              <MapaNacional
                data={this.state.data}
                path={this.state.path}
              />
            </Col>
            <Col xs={12} xl={4} sm={12} md={12} lg={4}>
              <TotalCasillasAprobadas
                data={this.state.data}
                path={this.state.path}
              ></TotalCasillasAprobadas>
              </Col>
          </Row> 

           <CasillasReportadasInstaladas data={this.state.data} />

          {/*<Caes data={this.state.data} path={this.state.path} />*/}

          <TipoAplicativo data={this.state.data} title={this.state.titulo} />
        </Container>

        {/* Modal */}
        <ModalInformativo
          show={this.state.showModal}
          onHide={() => this.close()}
          path={this.state.path}
        ></ModalInformativo>
      </div>
    );
  }

  openModal = _ => {
    this.setState({ showModal: true });
  };

  close = _ => {
    this.setState({ showModal: false });
  };
}

export default InstalacionCasillasNacional;

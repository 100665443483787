export const EnlacesGenerales = {
    centro_ayuda: "https://pef2021-sije-web.ine.mx/ayuda/#/QueEsSIJE"
}

export const EtiquetasHeader = {
    titulo_sistema : "Portal del Sistema de Información de la Jornada Electoral",
    titutlo_sub_sistema : "Proceso Electoral Federal 2020-2021"
}

export const EtiquetasFooter = {
    titulo_footer_uno : "Este sitio se visualiza mejor en las versiones más recientes de los navegadores web.",
    titulo_footer_dos : "Con base en la Ley Federal del Derecho de Autor queda prohibida cualquier modificación al diseño de este sitio.",
    titulo_footer_tres : "La modificación y/o destrucción de la información y/o contenido total o parcial de este sitio, es delito federal de acuerdo al Código Penal Federal",
    titulo_footer_cuatro: " - Instituto Nacional Electoral - Unidad Técnica de Servicios de Informática",
    titulo_footer_version: "12.0",
    titulo_footer_revision: "Rev 11",
    titulo_footer_rev_fecha_hora: "04/06/2021 23:00"
}


export const EtiquetasDatosInformativos = {
    etiqueta_ultimo_corte : "Último corte",
    etiqueta_tiempo_centro : "Tiempo del centro",
    etiqueta_intervalo_corte: "Corte generado cada: ",
    etiqueta_intervalo_corte_minutos: " minutos.",
    etiqueta_total : "Total",
    etiqueta_casillas_aprobadas : "casillas aprobadas"
}

export const EtiquetasBotones = {
    etiqueta_boton_actualizar  : "Actualizar",
    etiqueta_boton_base_datos : "Base de datos",
    etiqueta_boton_instalacion_casillas : "Ir a instalación de casillas",
    etiqueta_boton_segundo_reporte : "Ir a segundo reporte",
    etiqueta_boton_incidentes: "Ir a incidentes",
    etiqueta_boton_detalle_entidad : "Ver detalle por entidad"
}

export const EtiquetasGenerales = {
    etiqueta_general_mensaje_resumen : "Muestra información referente a la instalación de casillas, segundo reporte, así como los incidentes reportados.",
    etiqueta_general_total : "Total",
    etiqueta_general_casillas_aprobadas : "casillas aprobadas",
    etiqueta_general_instalacion_casillas : "Instalación de casillas",
    etiqueta_general_segundo_reporte : "Segundo reporte",
    etiqueta_general_incidentes: "Incidentes",
    etiqueta_general_casillas_reportadas_instaladas : "casillas instaladas",
    etiqueta_general_casillas_con_primer_reporte_concluido : "casillas con primer reporte concluido",
    etiqueta_general_casillas_reportadas : "casillas reportadas",
    etiqueta_general_casillas_reportadas_no_instaladas : "casillas no instaladas",
    etiqueta_general_casillas_segundo_reporte : "casilllas con segundo reporte",
    etiqueta_general_casillas_con_observadores : "casillas con Observadores electorales",
    etiqueta_general_casillas_con_representantes: "casillas con Representantes PP/ CI",
    etiqueta_general_casillas_incidente_reportado: "casillas con incidente reportado",
    etiqueta_general_incidente_reportado : "incidentes reportados",
    etiqueta_general_incidente_mas_reportado: " Incidentes más reportados por etapa de la Jornada Electoral",
    etiqueta_general_incidente_mas_reportado_explicacion: "Los incidentes SIJE se identifican mediante un conjunto de números y letras",
    etiqueta_general_incidente : "Incidente",
    etiqueta_general_reportes : "reportes",
    texto_informativo_ayuda : "Los datos mostrados se calculan con base al total de casillas aprobadas.",
    texto_informativo_ayuda_inst_casillas : "Casillas instaladas: El porcentaje se calcula como el número absoluto de casillas con Hora de Instalación, dividido entre el número de casillas aprobadas, por cien."
    + "\n "
    + "\n Casillas con primer reporte concluido. El porcentaje se calcula como el número absoluto de casillas con primer reporte concluido, dividido entre el número de casillas instaladas, por cien."
    + "\n "
    + "\n Casillas no instaladas. El porcentaje se calcula como el número absoluto de casillas reportadas como no instaladas, dividido entre el número de casillas aprobadas, por cien."
    + "\n "
    + "\n Mayor y Menor avance: El porcentaje se calcula como el número absoluto de casillas instaladas en la entidad, dividido entre el número de casillas aprobadas en cada entidad, por cien.",
    texto_informativo_ayuda_segundo_reporte : "Los datos mostrados se calculan con base al total de casillas instaladas",
    texto_informativo_ayuda_segundo_reporte_infografica_calculo: "Casillas con segundo reporte: El porcentaje se calcula como el número absoluto de casillas con segundo reporte, dividido entre el número de casillas con primer reporte concluido, por cien."
    + "\n"
    + "\n Casillas instaladas: El porcentaje se calcula como el número absoluto de casillas con Hora de Instalación, dividido entre el número de casillas aprobadas, por cien."
    + "\n"
    + "\n Casillas con primer reporte concluido. El porcentaje se calcula como el número absoluto de casillas con primer reporte concluido, dividido entre el número de casillas instaladas, por cien."
    + "\n"
    + "\n Casillas con Observadores electorales: El porcentaje se calcula con el número absoluto de casillas reportadas con observadores electorales, dividido entre el número de casillas con segundo reporte por cien."
    + "\n"
    + "\n Casillas con Representantes PP / CI: El porcentaje se calcula como el número de casillas con presencia de representantes propietarios, suplentes o generales, según partido político nacional, partido político local o candidatura independiente, dividido entre el número de casillas con segundo reporte, por cien."
    + "\n"
    + "\n Mayor y Menor avance: El porcentaje se calcula como el número absoluto de casillas instaladas en la entidad, dividido entre el número de casillas aprobadas en cada entidad, por cien.",
    etiqueta_general_sin_informacion_1 : "Aún no contamos con información para mostrar",
    etiqueta_general_sin_informacion_2 : "Inténtalo de nuevo en unos minutos", 
    etiqueta_general_sin_informacion_3 : "Los Capacitadores Asistentes Electorales están obteniendo los datos en las casillas, cuando estén disponibles se mostrarán aquí",
    etiqueta_general_ayuda_inst_casillas_resGral : "El cálculo porcentual de casillas instaladas se calcula con base al total de casillas aprobadas.",
    etiqueta_general_ayuda_seundo_resGral : "El cálculo porcentual de casillas con segundo reporte se calcula con base al total de casillas con primer reporte concluido."
    + "\n"
    + "\n El cálculo porcentual de casillas con observadores electorales se calcula con base al total de casillas con segundo reporte."
    + "\n"
    + "\n El cálculo porcentual de casillas con representantes PP/CI se calcula con base al total de casillas con segundo reporte.",
    etiqueta_general_ayuda_incidentes_resGral : "El cálculo porcentual de casillas con incidentes reportados se calcula con base al total de casillas aprobadas.",
    etiqueta_general_calculo_primer_reporte_concluido_resGral : "El cálculo porcentual de casillas con primer reporte concluido se calcula con base al total de casillas instaladas."
}

export const EtiquetasResumenGeneral = {
    etiqueta_modal_informativo_grafica_casillas_instaladas           : "Los datos mostrados se calculan con base al total de casillas reportadas.",
    etiqueta_modal_informativo_grafica_casillas_primer_rep_concluido : "Los datos mostrados se calculan con base al total de casillas instaladas.",
    etiqueta_modal_informativo_grafica_casillas_segundo_reporte      : "Los datos mostrados se calculan con base al total de casillas con primer reporte concluido.",
    etiqueta_descripcion_ejey_grafica_general                       : "Porcentaje",
    etiqueta_descripcion_ejey_grafica_casillas_instaladas            : "Porcentaje de casillas aprobadas",
    etiqueta_descripcion_ejey_grafica_casillas_primer_rep_concluido  : "Porcentaje de casillas  instaladas",
    etiqueta_descripcion_ejey_grafica_casillas_segundo_reporte       : "Porcentaje de casillas con primer reporte concluido"
}

export const EtiquetasSubEncabezado = {
    etiqueta_subencabezado_general_nacional : "Muestra información referente a la instalación de casillas (primer reporte concluido) y segundo reporte.",
    etiqueta_subencabezado_general_entidad : "Muestra información referente a la instalación de casillas (primer reporte concluido) y segundo reporte.",
    etiqueta_subencabezado_instalacion_nacional : "Muestra el avance de instalación de casillas, basado en los reportes realizados por las y los Capacitadores Asistentes Electorales (CAE)",
    etiqueta_subencabezado_instalacion_entidad : "Muestra el avance de instalación de casillas, basado en los reportes realizados por las y los Capacitadores Asistentes Electorales (CAE)", 
    etiqueta_subencabezado_incidentes_nacional : "Muestra el avance de instalación de casillas, basado en los reportes realizados por las y los Capacitadores Asistentes Electorales (CAE)",
    etiqueta_subencabezado_incidentes_entidad : "Muestra el avance de instalación de casillas, basado en los reportes realizados por las y los Capacitadores Asistentes Electorales (CAE)"
}

export const EtiquetasIntalacionCasillas = {
    etiqueta_instalacion_casillas_aprobadas  : "casillas aprobadas",
    etiqueta_instalacion_casillas_reportadas_instaladas  : "casillas instaladas",
    etiqueta_instalacion_casillas_reportadas : "casillas reportadas",
    etiqueta_instalacion_casillas_con_primer_reporte_concluido : "casillas con primer reporte concluido",
    etiqueta_instalacion_casillas_reportadas_no_instaladas : "casillas no instaladas",
    etiqueta_instalacion_mayor_avance: "Mayor avance:",
    etiqueta_instalacion_menor_avance : "Menor avance:",
    etiqueta_instalacion_Casillas_instaladas : "Casillas instaladas",
    etiqueta_instalacion_casillas_instaladas : "casillas instaladas",
    etiqueta_instalacion_seleccion_entidad_mensaje : "Casillas instaladas por Distritos Federales",
    etiqueta_instalacion_cae : "CAE han reportado estatus de instalación de casillas",
}


export const EtSegRep = {
    segundo_reporte_nacional : "Muestra los datos reportados por las y los Capacitadores Asistentes Electorales (CAE), posterior a la instalación de casillas",
    segundo_reporte_entidad : "Muestra los datos reportados por las y los Capacitadores Asistentes Electorales (CAE), posterior a la instalación de casillas",
    segundo_reporte_distritos_federales : "Muestra los datos reportados por las y los Capacitadores Asistentes Electorales (CAE), posterior a la instalación de casillas.",
    casillas_con_segundo_reporte_titulo_nacional : "Casillas con Segundo reporte",
    casillas_con_segundo_reporte_titulo_entidad : "Casillas con Segundo reporte por Distritos Federales",
    casillas_con_segundo_reporte : "casillas con Segundo reporte",
    casillas_con_observadores : "casillas con Observadores electorales",
    casillas_con_representantes : " casillas con Representantes PP / CI",
    presidente : "Presidente",
    "01_presidente" : "Presidente",
    secretario : "Secretario",
    secretario1 : "1er Secretario",
    "02_secretario1" : "1er Secretario",
    secretario2 : "2do Secretario",
    "03_secretario2" : "2do Secretario",
    primerEscrutador : "1er escrutador",
    "04_primerEscrutador" : "1er escrutador",
    segundoEscrutador : "2do escrutador",
    "05_segundoEscrutador" : "2do escrutador",
    tercerEscrutador : "3er escrutador",
    "06_tercerEscrutador" : "3er escrutador",
    observadores_electorales : "Observadores Electorales",
    capacitadores_asistentes : "Capacitadores Asistentes Electorales (CAE)",
    cae: "CAE",
    cae_segundo_reprorte : "CAE han enviado Segundo reporte",
    reporte_aplicativo: "Reportes por tipo de aplicativo",
    casillas_reportadas : "casillas reportadas",
    mayor_avance: "Mayor avance:",
    menor_avance : "Menor avance:",
    etiqueta_instalacion_seleccion_entidad_mensaje : "Casillas instaladas por Distritos Federales"
}


export const EtIncidentes = {
    incidentes_nacional : "Muestra todos los sucesos que interfieren con el óptimo desarrollo de la Jornada Electoral, según su clasificación en el ",
    incidentes_entidad : "Muestra todos los sucesos que interfieren con el óptimo desarrollo de la Jornada Electoral, según su clasificación en el ",
    incidentes_reportados : "Todos los incidentes reportados",
    incidentes_catalogo_sije : "Catálogo de incidentes SIJE",
    incidentes_casillas: "Casillas con Incidentes",
    incidentes_menos : "Menos incidentes reportados",
    incidente_mas : "Más incidentes reportados"
}



import React from "react";
//Plugins
import Highcharts, { numberFormat } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col } from "react-bootstrap";

//Components
import { formatNumber, formatPorcentajeDoubleEs } from "../Auxiliares/ConvertNumber";


const RepresentatesPPCI = props => {
  const info = props.data.representantesPPCI;



  const optionsGraficaUno = {
    credits: {
      enabled: false
    },

    chart: {
      marginBottom: -80,
      marginLeft: 0,
      marginRight: 0,
      marginTop: -95,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false
    },

    title: {
      text: "",
      align: "center",
      verticalAlign: "middle",
      y: 40
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>"
    },

    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#4D4B75", "#9876ce", "#BF6DA0"],
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white"
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "100%"
      }
    },

    series: [
      {
        type: "pie",
        name: "Total",
        innerSize: "90%",
        data: [
          { name: "Propietario", y: info.propietario.porcentaje },
          { name: "Suplente", y: info.suplente.porcentaje },
          { name: "General", y: info.general.porcentaje }
        ]
      }
    ]
  };

  return (
    <div className="contenido">
      <p className="preludio">
        Las y los <strong>{formatNumber(info.total)}</strong> Representantes PP / CI
        que han asistido a las casillas son:
      </p>

      <Row>
        <Col xs={12} xl={12} sm={12} md={12} lg={12} className="contRepr">
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { height: "100%", witdh: "100%" } }}
            options={optionsGraficaUno}
          />
        </Col>
      </Row>

      <Row className="text-center">
        <Col xs={4} xl={4} sm={4} md={4} lg={4} className="repProp">
          <p className="enfasis1">{formatPorcentajeDoubleEs(info.propietario.porcentaje)}%</p>
          <div className="row lineaMedia">
            <div className="circulo"></div>
          </div>
          {info.propietario.total != null ?
            (<p className="enfasis2">{formatNumber(info.propietario.total)}</p>)
            : (<p className="enfasis2">{0}</p>)
          }
          <p>Propietario</p>
        </Col>
        <Col xs={4} xl={4} sm={4} md={4} lg={4} className="repSup">
          <p className="enfasis1">{formatPorcentajeDoubleEs(info.suplente.porcentaje)}%</p>
          <div className="row lineaMedia">
            <div className="circulo"></div>
          </div>
          {info.suplente.total != null ?
            (<p className="enfasis2">{formatNumber(info.suplente.total)}</p>)
            : (<p className="enfasis2">{0}</p>)
          }
          <p>Suplente</p>
        </Col>
        <Col xs={4} xl={4} sm={4} md={4} lg={4} className="repGral">
          <p className="enfasis1">{formatPorcentajeDoubleEs(info.general.porcentaje)}%</p>
          <div className="row lineaMedia">
            <div className="circulo"></div>
          </div>
          {info.general.total != null ?
            (<p className="enfasis2">{formatNumber(info.general.total)}</p>)
            : (<p className="enfasis2">{0}</p>)
          }

          <p>General</p>
        </Col>
      </Row>

    </div>
  );
};

export default RepresentatesPPCI;

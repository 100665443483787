import React, {Component} from 'react';
import Carga from '../../Assets/images/loadingSIJE.gif';

class Cargando extends Component {
    render (){
        return(
            <div className="cargandoCont">
                <div className="cargando">
                    <img className="mb-4" src={Carga} alt="cargando" />
                    <p>Obteniendo datos</p>
                </div>
            </div>
        )
    }
}

export default Cargando;
import React from "react";
//Plugins
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col } from "react-bootstrap";

//Components
import { formatNumber, formatPorcentaje, formatPorcentajeDoubleEs } from "../Auxiliares/ConvertNumber";
import { EtiquetasBotones, EtSegRep } from "../../Constants/ConstantesGlobales";
import { obtenerAbreviaturaEntidad } from "../../Components/Auxiliares/TextUtils";
import Botones from '../Genericos/Botones';

const TotalCasillasAprobadasSegundoReporte = props => {
  var arreglo = props.path.split("/");
  var nivel = arreglo[arreglo.length - 1];
  const bandera = nivel == 2 ? false : true;
  const idEntidad = props.data.id;

  const info = props.data.totales;
  const infoMayorAvance = props.data.mayorAvance;
  const infoMenorAvance = props.data.menorAvance;

  //Bandera que controla la componente de Mayor y Menor Avance
  const bandera2 = infoMayorAvance.total != null && infoMenorAvance.total != null ? true : false;

  const banderaRenderMayMenAvance = infoMayorAvance.total == undefined ? false : true;
  const mismoDtoAvance = infoMenorAvance.id == infoMayorAvance.id ? true : false;
  //const renderMayorAvance = (infoMenorAvance.porcentaje == 100 && infoMayorAvance.porcentaje == 100) && (mismoDtoAvance == true) ? true : false;
  //const renderMenorAvance = (infoMenorAvance.porcentaje == 0  && infoMayorAvance.porcentaje  == 0 ) && (mismoDtoAvance == true ) ? true : false;

  //const menorMayorIgual = infoMenorAvance.porcentaje == 0  && infoMayorAvance.porcentaje  == 0 ? true : false;

  const renderMenorAvance = (infoMenorAvance.porcentaje == 0 && infoMayorAvance.porcentaje == 0) ? true : false;
  const renderMayorAvance = (infoMenorAvance.porcentaje == 100 && infoMayorAvance.porcentaje == 100) ? true : false;
  const menorMayorIgual = infoMenorAvance.porcentaje == infoMayorAvance.porcentaje ? true : false;

  var faltantesUno;

  if (100 - info.porcentajeCasillasSegundo === 0) {
    faltantesUno = null;
  } else {
    faltantesUno = 100 - info.porcentajeCasillasSegundo;
  }

  const optionsGraficaUno = {
    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      renderTo: "container",
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: "rgba(0,0,0,0)"
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#bf6da0", "#ececec"],
        dataLabels: {
          enabled: false
        }
      },
      states: {
        hover: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
    },

    series: [
      {
        name: "Total",
        data: [
          { name: "Instaladas", y: info.porcentajeCasillasSegundo },
          { name: "Faltantes", y: faltantesUno }
        ]
      }
    ]
  };

  return (
    <div>
      <div className="contenido borde">

        <p className="preludio2">
          Total: <br />

          <span className="enfasis2">{formatNumber(info.totalCasillasInstaladas)}</span>{" "}
          casillas instaladas
        </p>

        <p className="preludio2">
          <span className="enfasis2">{formatNumber(info.totalCasillasPrimRepConc)}</span>{" "}
          casillas con primer reporte concluido
        </p>



        <Row>
          <Col>
            <Row>
              <div className="graficaPie">
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { height: "100%" } }}
                  options={optionsGraficaUno}
                />
              </div>
              <div>
                <p className="enfasis1">{formatPorcentajeDoubleEs(info.porcentajeCasillasSegundo)}%</p>
                <p>
                  <strong>{formatNumber(info.totalCasillasSegundo)}</strong>{" "}
                  {EtSegRep.casillas_con_segundo_reporte}{" "}
                </p>
              </div>
            </Row>
          </Col>
        </Row>

        <hr></hr>

        <p className="preludio2">
          De las <span className="enfasis2">{formatNumber(info.totalCasillasSegundo)}</span>{" "}
          casillas con Segundo reporte
        </p>

        <Row>
          <Col>
            <Row className="mb-2">
              <div className="graficaPie">
                <i className="fa fa-eye bigIcon mt-2"></i>
              </div>
              <div>
                <p className="enfasis1">
                  {formatPorcentajeDoubleEs(info.porcentajeCasillasObservadores)}%
                </p>
                <strong>{formatNumber(info.totalCasillasObservadores)}</strong>{" "}
                <span>{EtSegRep.casillas_con_observadores}</span>
              </div>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Row>
              <div className="graficaPie">
                <i className="fa fa-user bigIcon mt-2"></i>
              </div>
              <div>
                <p className="enfasis1">
                  {formatPorcentajeDoubleEs(info.porcentajeCasillasRepresentantes)}%
                </p>
                <strong>
                  {formatNumber(info.totalCasillasRepresentantes)}
                </strong>{" "}
                <span>{EtSegRep.casillas_con_representantes}</span>
              </div>
            </Row>
          </Col>
        </Row>
      </div>

      <div>
        {(bandera && bandera2) ? (


          <Row className="lineaVertical">

            <Col xs={6} xl={6} sm={6} md={6} lg={6}>
              <div
                className={
                  "entidadMasMenos " + obtenerAbreviaturaEntidad(infoMayorAvance.nombre)
                }
              ></div>
              <div>
                <p className="masMenosSub">{EtSegRep.mayor_avance}</p>
                <p className="enfasis2">
                  {infoMayorAvance.nombre}
                </p>
                <p className="enfasis2">{formatPorcentajeDoubleEs(infoMayorAvance.porcentaje)}%</p>
                <p>
                  <strong>{formatNumber(infoMayorAvance.total)}</strong>
                  {EtSegRep.casillas_con_segundo_reporte}
                </p>
              </div>
            </Col>
            <Col xs={6} xl={6} sm={6} md={6} lg={6}>
              <div
                className={
                  "entidadMasMenos " + obtenerAbreviaturaEntidad(infoMenorAvance.nombre)
                }
              ></div>
              <div>
                <p className="masMenosSub">{EtSegRep.menor_avance}</p>
                <p className="enfasis2">
                  {infoMenorAvance.nombre}
                </p>
                <p className="enfasis2">{formatPorcentajeDoubleEs(infoMenorAvance.porcentaje)}%</p>
                <p>
                  <strong>{formatNumber(infoMenorAvance.total)}</strong>
                  {EtSegRep.casillas_con_segundo_reporte}
                </p>
              </div>
            </Col>
          </Row>
        ) : (

          <div>

            {banderaRenderMayMenAvance ? (

              <div>

                {renderMayorAvance ? (

                  <Row >
                    <Col xs={12} xl={12} sm={12} md={12} lg={12} className="text-center">
                      <div>
                        <p className="masMenosSub">{EtSegRep.mayor_avance}</p>
                        <p className="small mb-2">{infoMayorAvance.nombre}</p>
                        <p className="enfasis2">Distrito {infoMayorAvance.id}</p>
                        <p className="enfasis2">{formatPorcentajeDoubleEs(infoMayorAvance.porcentaje)}%</p>
                        <p>
                          <strong className="mr-1">
                            {formatNumber(infoMayorAvance.total)}
                          </strong>
                          {EtSegRep.casillas_con_segundo_reporte}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ) : (<div>

                  {renderMenorAvance ? (
                    <Row >
                      <Col xs={12} xl={12} sm={12} md={12} lg={12} className="text-center">
                        <div>BB
                                        <p className="masMenosSub">{EtSegRep.menor_avance}</p>
                          <p className="small mb-2">{infoMenorAvance.nombre}</p>
                          <p className="enfasis2">Distrito {infoMenorAvance.id}</p>
                          <p className="enfasis2">{formatPorcentajeDoubleEs(infoMenorAvance.porcentaje)}%</p>
                          <p>
                            <strong className="mr-1">
                              {formatNumber(infoMenorAvance.total)}
                            </strong>
                            {EtSegRep.casillas_con_segundo_reporte}
                          </p>
                        </div>

                      </Col>
                    </Row>
                  ) : (
                    <div>

                      {!renderMenorAvance && !renderMayorAvance ? (

                        <div>

                          {menorMayorIgual ? (

                            <Row >
                              <Col xs={12} xl={12} sm={12} md={12} lg={12} className="text-center">
                                <div>
                                  <p className="masMenosSub">{EtSegRep.mayor_avance}</p>
                                  <p className="small mb-2">{infoMayorAvance.nombre}</p>
                                  <p className="enfasis2">Distrito {infoMayorAvance.id}</p>
                                  <p className="enfasis2">{formatPorcentajeDoubleEs(infoMayorAvance.porcentaje)}%</p>
                                  <p>
                                    <strong className="mr-1">
                                      {formatNumber(infoMayorAvance.total)}
                                    </strong>
                                    {EtSegRep.casillas_con_segundo_reporte}
                                  </p>
                                </div>

                              </Col>
                            </Row>

                          ) : (<div>


                            <Row className="lineaVertical">
                              <Col xs={6} xl={6} sm={6} md={6} lg={6} className="text-center">
                                <div>
                                  <p className="masMenosSub">{EtSegRep.mayor_avance}</p>
                                  <p className="small mb-2">{infoMayorAvance.nombre}</p>
                                  <p className="enfasis2">Distrito {infoMayorAvance.id}</p>
                                  <p className="enfasis2">{formatPorcentajeDoubleEs(infoMayorAvance.porcentaje)}%</p>
                                  <p>
                                    <strong className="mr-1">
                                      {formatNumber(infoMayorAvance.total)}
                                    </strong>
                                    {EtSegRep.casillas_con_segundo_reporte}
                                  </p>
                                </div>
                              </Col>

                              <Col xs={6} xl={6} sm={6} md={6} lg={6} className="text-center">
                                <div>
                                  <p className="masMenosSub">{EtSegRep.menor_avance}</p>
                                  <p className="small mb-2">{infoMenorAvance.nombre}</p>
                                  <p className="enfasis2">Distrito {infoMenorAvance.id}</p>
                                  <p className="enfasis2">{formatPorcentajeDoubleEs(infoMenorAvance.porcentaje)}%</p>
                                  <p>
                                    <strong className="mr-1">
                                      {formatNumber(infoMenorAvance.total)}
                                    </strong>
                                    {EtSegRep.casillas_con_segundo_reporte}
                                  </p>
                                </div>

                              </Col>
                            </Row>

                          </div>)}

                        </div>


                      ) : (<div></div>)}


                    </div>)}

                </div>)}


              </div>


            ) : (
              <div></div>
            )
            }
          </div>

        )
        }

        <Row className="ocultarMovil mb-3">
          <Col>
            <Botones
              tipoBoton="2"
              etiqueta={EtiquetasBotones.etiqueta_boton_base_datos}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TotalCasillasAprobadasSegundoReporte;

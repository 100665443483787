import Mapa1 from "../MapasEstados/Entidad1";
import Mapa2 from "..//MapasEstados/Entidad2";
import Mapa3 from "..//MapasEstados/Entidad3";
import Mapa4 from "..//MapasEstados/Entidad4";
import Mapa5 from "../MapasEstados/Entidad5";
import Mapa6 from "../MapasEstados/Entidad6";
import Mapa7 from "../MapasEstados/Entidad7";
import Mapa8 from "../MapasEstados/Entidad8";
import Mapa9 from "../MapasEstados/Entidad9";
import Mapa10 from "../MapasEstados/Entidad10";
import Mapa11 from "../MapasEstados/Entidad11";
import Mapa12 from "../MapasEstados/Entidad12";
import Mapa13 from "../MapasEstados/Entidad13";
import Mapa14 from "../MapasEstados/Entidad14";
import Mapa15 from "../MapasEstados/Entidad15";
import Mapa16 from "../MapasEstados/Entidad16";
import Mapa17 from "../MapasEstados/Entidad17";
import Mapa18 from "../MapasEstados/Entidad18";
import Mapa19 from "../MapasEstados/Entidad19";
import Mapa20 from "../MapasEstados/Entidad20";
import Mapa21 from "../MapasEstados/Entidad21";
import Mapa22 from "../MapasEstados/Entidad22";
import Mapa23 from "../MapasEstados/Entidad23";
import Mapa24 from "../MapasEstados/Entidad24";
import Mapa25 from "../MapasEstados/Entidad25";
import Mapa26 from "../MapasEstados/Entidad26";
import Mapa27 from "../MapasEstados/Entidad27";
import Mapa28 from "../MapasEstados/Entidad28";
import Mapa29 from "../MapasEstados/Entidad29";
import Mapa30 from "../MapasEstados/Entidad30";
import Mapa31 from "../MapasEstados/Entidad31";
import Mapa32 from "../MapasEstados/Entidad32";

export const MapasEntidades = {
    mapa1: Mapa1,
    mapa2: Mapa2,
    mapa3: Mapa3,
    mapa4: Mapa4,
    mapa5: Mapa5,
    mapa6: Mapa6,
    mapa7: Mapa7,
    mapa8: Mapa8,
    mapa9: Mapa9,
    mapa10: Mapa10,
    mapa11: Mapa11,
    mapa12: Mapa12,
    mapa13: Mapa13,
    mapa14: Mapa14,
    mapa15: Mapa15,
    mapa16: Mapa16,
    mapa17: Mapa17,
    mapa18: Mapa18,
    mapa19: Mapa19,
    mapa20: Mapa20,
    mapa21: Mapa21,
    mapa22: Mapa22,
    mapa23: Mapa23,
    mapa24: Mapa24,
    mapa25: Mapa25,
    mapa26: Mapa26,
    mapa27: Mapa27,
    mapa28: Mapa28,
    mapa29: Mapa29,
    mapa30: Mapa30,
    mapa31: Mapa31,
    mapa32: Mapa32,
}

export default MapasEntidades;
import React from "react";

const Encabezado = ({descripcionSeccion, descripcionNivel}) => {
  return (
    <div>
      <h1>
        <span className="resumenRosa">{descripcionSeccion}</span>
        <span className="resumenMorado">{descripcionNivel}</span>
      </h1>
    </div>
  );
};

export default Encabezado;

import React, { Component } from "react";
//Bootstrap React
import { Container, Row, Col, Nav, Modal, Button, Accordion, Card } from "react-bootstrap";
import jsonMenu from '../../Assets/json/Menu/Menu.json';

class Menu extends Component {

    constructor() {
        super();
        this.state = {
          data: jsonMenu.secciones
        };
    }

    getInitialState = _ => {
        return this.setState({ showModal: false });
      };
    
      close = _ => {
        this.setState({ showModal: false });
      };
    
      openModal = _ => {
        this.setState({ showModal: true });
      };

    render(){
        if (this.state.showModal == null) {
            this.setState({ showModal: false });
            return <div>Loading...</div>;
        }

        return(
            <div className="menuResponsivo">
                <Row className="text-center">
                    <Col sm={4} xs={4}>
                        <a href="/"><i className="fa fa-home"></i></a>
                    </Col>
                    <Col sm={4} xs={4}>
                        <a href="https://intranet.ine.mx/tutoriales/sistemas/Electorales/2019-2020/Local/PortalSIJE/" target="_blank" rel="noopener noreferrer"><i className="fa fa-question-circle"></i> Ayuda</a>
                    </Col>
                    <Col sm={4} xs={4}>
                        <a href="#" onClick={this.openModal}><i className="fa fa-bars"></i></a>
                    </Col>
                </Row>
                <Modal 
                    show={this.state.showModal}
                    onHide={this.close}
                    animation={false}
                    className="modalResponsivo">
                    <Container>
                        <Col className="text-right p-0">
                            <Button onClick={this.close}>
                                <i className="fa fa-times-circle"></i>
                            </Button>
                        </Col>
                        <Col>
                            <Accordion>
                                <Card>
                                    {this.state.data.map((info, index) => (
                                        <Row key={index}>
                                            <Accordion.Toggle eventKey={index} as={Card.Header}>
                                                    {info.descripcionSeccion}
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={index}>
                                                <Card.Body>
                                                    <Nav>
                                                        {renderListItems(info)}
                                                    </Nav>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Row>
                                    ))}
                                </Card>
                            </Accordion>
                        </Col>
                    </Container>
                </Modal>
                
            </div>
        )
    }
}

const renderListItems = props => {
    var items = [];
    for (var i = 0; i < props.nivelNavegacion.length; i++) {
      var nombreNivel = props.nivelNavegacion[i].descripcionNivel;
      var ruta = props.nivelNavegacion[i].rutaMenu;
      var idSeccion = props.idSeccion;
      var idNivel = props.nivelNavegacion[i].idNivel;
      items.push(
        <Nav.Link key={i} eventKey="1" href={`/${ruta}/${idSeccion}/${idNivel}`} >
          {nombreNivel}
        </Nav.Link>
      );
    }
    return items;
  };

export default Menu;
import React, {Component} from 'react';
import { Container } from 'react-bootstrap';
// import {Link} from 'react-router-dom';

//Ulises
import {HashLink as Link} from 'react-router-hash-link';
import { NavHashLink as NavLink } from 'react-router-hash-link';
//import AccionesComunes from '../AccionesComunes';


class Glosario extends Component {

    unaFuncion = () => {
        console.log("ubicación", this.props.location);
    };

    componentDidMount(){
        document.title = "Glosario"
        var activeMenu = document.getElementById("Glosariolink");

        window.scrollTo(0, 0);
    }

    /*componentWillUnmount(){
        document.getElementById("menuNavegacion").classList.remove("active");
        
        /*document.getElementById("FuncCasilla").classList.remove("active");
        document.getElementById("jornadaElec").classList.remove("active");
        document.getElementById("preguntasFrec").classList.remove("active");
        document.getElementById("Glosariolink").classList.remove("active");
        
        var colapsarMenu = document.getElementById("menuResponsivo");
        //colapsarMenu.classList.replace("show", "not");
    }*/

    render () {
        return (
            <div>
              
              <Container>
                {/*<AccionesComunes />
                <div className="migajas">
                    <small>
                        <Link to="./QueEsSIJE">Inicio</Link>
                        <span>Glosario</span>
                    </small>
                </div>
                {/* <Migajas /> */}
                <br /><br />
                <div className="col-md-12 text-center mb-5">
                    <h1 className="tituloPagina"><b>Glosario</b></h1>
                </div>
                <div id="letras-nav" data-spy="scroll" data-target="#myScrollspy" data-offset="20" className="navbar">
                  <nav>
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <NavLink smooth to="./Glosario#a" className="nav-link"><b>A</b></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink smooth to="./Glosario#c" className="nav-link"><b>C</b></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink smooth to="./Glosario#m" className="nav-link"><b>M</b></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink smooth to="./Glosario#o" className="nav-link"><b>O</b></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink smooth to="./Glosario#p" className="nav-link"><b>P</b></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink smooth to="./Glosario#r" className="nav-link"><b>R</b></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink smooth to="./Glosario#s" className="nav-link"><b>S</b></NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div data-spy="scroll" data-target="#letras-nav" data-offset="-75">
                    <div className="row bordes" id="a">
                        <div className="col-md-12 numerales">
                            <h3>A</h3>
                        </div>
                        <div className="termino col-md-12 col-xs-8">
                            <h4>ARE</h4>
                            <p>Área de Responsabilidad Electoral. Es el espacio geográfico en el que las y los CAE desarrollarán las actividades de capacitación y asistencia electoral, está integrada por una parte, una o varias secciones electorales donde se prevé instalar un número determinado de casillas.</p>
                        </div>
                    </div>
                    <div className="row bordes" id="c">
                        <div className="col-md-12 numerales">
                            <h3>C</h3>
                        </div>
                        <div className="termino col-md-12">
                            <h4>CAE</h4>
                            <p>Capacitador Asistente Electoral. Persona encargada de realizar el reporte de información al SIJE.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Candidato Independiente</h4>
                            <p>El ciudadano que obtenga por parte de la autoridad electoral el acuerdo de registro, habiendo cumplido los requisitos que para tal efecto que establece la Ley General de Instituciones y Procedimientos Electorales.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Casillas aprobadas</h4>
                            <p>Son las casillas aprobadas por los Consejos Distritales.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Casillas con primer reporte concluido</h4>
                            <p>Son aquellas casillas de las cuales ya se reportó la totalidad de la información correspondiente al primer reporte.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Casillas con segundo reporte</h4>
                            <p>Son las casillas donde ya se realizó el reporte de la segunda visita de la/el CAE.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Casillas instaladas</h4>
                            <p>Son aquellas casillas de las cuales ya se reportó la hora de instalación.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Casillas no instaladas</h4>
                            <p>Son las casillas que tienen un incidente de casilla no instalada.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Casillas reportadas</h4>
                            <p>Son las casillas reportadas como instaladas y casillas con incidente de casilla no instalada.</p>
                        </div>
                    </div>
                    <div className="row bordes" id="m">
                        <div className="col-md-12 numerales">
                            <p>M</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Mesas directivas de Casilla</h4>
                            <p>Son los órganos electorales formados por ciudadanos, facultados para recibir la votación y realizar el escrutinio y cómputo en cada una de las secciones electorales en que se dividan los 300 distritos electorales y las demarcaciones electorales de las entidades de la República.</p>
                            <p>Las mesas directivas de casilla como autoridad electoral tienen a su cargo, durante la jornada electoral, respetar y hacer respetar la libre emisión y efectividad del sufragio, garantizar el secreto del voto y asegurar la autenticidad del escrutinio y cómputo.</p>
                        </div>
                    </div>
                    <div className="row bordes" id="o">
                        <div className="col-md-12 numerales">
                            <h3>O</h3>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Observadores Electorales</h4>
                            <p>Ciudadanos mexicanos que participan en la observación del proceso electoral conforme a lo establecido en la ley.</p>
                        </div>
                    </div>
                    <div className="row bordes" id="p">
                        <div className="col-md-12 numerales">
                            <h3>P</h3>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Partidos políticos (PP)</h4>
                            <p>Los partidos políticos son entidades de interés público con personalidad jurídica y patrimonio propios, con registro legal ante el Instituto Nacional Electoral o ante los Organismos Públicos Locales, y tienen como fin promover la participación del pueblo en la vida democrática, contribuir a la integración de los órganos de representación política y, como organizaciones de ciudadanos, hacer posible el acceso de éstos al ejercicio del poder público.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Partidos políticos Locales (PPL)</h4>
                            <p>Partido político con registro ante el Organismo Público Local.</p>
                        </div>
                    </div>
                    <div className="row bordes" id="r">
                        <div className="col-md-12 numerales">
                            <h3>R</h3>
                        </div>
                        <div className="termino col-md-12">
                            <h4>Representante de Partido Político y Candidaturas Independientes</h4>
                            <p>Es una persona registrada por un Partido Político o Candidatura Independiente para representarlo ante el Instituto Nacional Electoral. Cada Partido Político o Candidatura Independiente una vez registrados sus candidatos, fórmulas y listas, y hasta trece días antes del día de la elección, tendrán derecho a nombrar dos representantes propietarios y un suplente, ante cada mesa directiva de casilla, y representantes generales propietarios, tomando en consideración lo siguiente:</p>
                            <ul><li>En elección federal cada Partido Político o Candidato Independiente, según sea el caso, podrá acreditar un representante propietario y un suplente.</li></ul>
                            <ul><li>En elección local cada Partido Político, coalición, o Candidato Independiente, según sea el caso, podrá acreditar un representante propietario y un suplente.</li></ul>
                            <ul><li>Los Partidos Políticos podrán acreditar en cada uno de los distritos electorales uninominales un representante general por cada diez casillas electorales ubicadas en zonas urbanas y uno por cada cinco casillas rurales.</li></ul>
                            <br />
                            <p>Los representantes de los Partidos Políticos y de Candidatos Independientes debidamente acreditados ante las Mesas Directivas de Casilla tendrán los siguientes derechos:</p>
                            <ul><li>Participar en la instalación de la casilla y contribuir al buen desarrollo de sus actividades hasta su clausura. Tendrán el derecho de observar y vigilar el desarrollo de la elección;</li></ul>
                            <ul><li>Recibir copia legible de las actas de instalación, cierre de votación y final de escrutinio elaboradas en la casilla;</li></ul>
                            <ul><li>Presentar escritos relacionados con incidentes ocurridos durante la votación;</li></ul>
                            <ul><li>Presentar al término del escrutinio y del cómputo escritos de protesta;</li></ul>
                            <ul><li>Acompañar al presidente de la mesa directiva de casilla, al consejo distrital correspondiente, para hacer entrega de la documentación y el expediente electoral.</li></ul>
                            <ul><li>Firmar todas las actas que se levanten, pudiéndolo hacer bajo protesta con mención de la causa que la motiva.</li></ul>
                        </div>
                    </div>
                    <div className="row bordes" id="s">
                        <div className="col-md-12 numerales">
                            <h3>S</h3>
                        </div>
                        <div className="termino col-md-12">
                            <h4>SIJE</h4>
                            <p>El Sistema de Información sobre el desarrollo de la Jornada Electoral es un proceso de recopilación, transmisión, captura y disposición de información que se implementa en las Juntas Distritales Ejecutivas del Instituto, bajo la supervisión de las Juntas Locales Ejecutivas, con el fin de dar seguimiento, a través de una herramienta informática, a los aspectos más importantes que se presentan el día de la jornada electoral en las casillas electorales.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>SIJE móvil (app SIJE)</h4>
                            <p>Es una aplicación móvil instalada en los teléfonos celulares asignados a las y los CAE, donde se captura y transmite la información de forma directa sobre el desarrollo de la Jornada Electoral.</p>
                        </div>
                        <div className="termino col-md-12">
                            <h4>SIJE web</h4>
                            <p>Es una herramienta informática, que realiza un proceso de recopilación, transmisión, captura y disposición de la información de los aspectos más importantes que se presentan el día de la Jornada Electoral.</p>
                        </div>
                    </div>
                </div>
              </Container>
            </div>
        )
    }
}

export default Glosario;
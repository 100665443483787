import React, { Component } from "react";
import { EtiquetasGenerales } from "../../Constants/ConstantesGlobales";
import { Row, Col, Card, Container } from "react-bootstrap";
import { EtiquetasFooter } from '../../Constants/ConstantesGlobales';

class SinInfo extends Component {
  render() {
    return (
      <>
        <hr />
        <Container className="margen-100-auto">
          <Row className="justify-content-md-center">
            <Col md="8" lg="7" sm="12">
              <Card className="mt-4 mb-4 p-4">
                <Row>
                  <Col md="4" lg="3" className="text-center">
                    <i className="fas fa-info-circle sinInfoIcon"></i>&nbsp;
                    </Col>
                  <Col md="8" lg="9">
                    <h2>{EtiquetasGenerales.etiqueta_general_sin_informacion_1}</h2>
                    <p>
                      {EtiquetasGenerales.etiqueta_general_sin_informacion_2}
                    </p>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Col>
                  <p>{EtiquetasGenerales.etiqueta_general_sin_informacion_3}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SinInfo;

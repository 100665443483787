import React from "react";
import { Row, Col } from "react-bootstrap";

import { formatNumber } from "../Auxiliares/ConvertNumber";
import { EtSegRep } from "../../Constants/ConstantesGlobales";


const ObservadoresElectorales = props => {
  
  const info = props.data.observadores;
  return (
    <div className="contenido borde">
        <Row>
            <Col xs={12} xl={12} sm={12} md={12} lg={12} className="text-center">
                <i className="fa fa-eye bigIcon ml-0"></i>
            </Col>
            <Col xs={12} xl={12} sm={12} md={12} lg={12} className="text-center">
              {info.total != null ? 
                (<p className="enfasis1">{formatNumber(info.total)}</p>) 
                :(<p className="enfasis1">{0}</p>)
              }
              <p><strong>{EtSegRep.observadores_electorales}</strong></p>
            </Col>
        </Row>
    </div>
  );
};



export default ObservadoresElectorales;

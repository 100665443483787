import React from "react";
import { Modal } from "react-bootstrap";
import { EtiquetasGenerales } from "../../Constants/ConstantesGlobales";
import nl2br from "react-newline-to-break";

const ModalInformativo = props => {
  var path = props.path;
  var arreglo = path.split("/");
  var secciones = arreglo[2];

  let mensaje = "";
  switch (Number(secciones)) {
    case 1:
      mensaje = EtiquetasGenerales.texto_informativo_ayuda;
      break;
    case 2:
      mensaje = EtiquetasGenerales.texto_informativo_ayuda_inst_casillas;
      break;
    case 3:
      mensaje = EtiquetasGenerales.texto_informativo_ayuda_segundo_reporte_infografica_calculo;
      break;
    case 4:
      mensaje = EtiquetasGenerales.etiqueta_general_ayuda_inst_casillas_resGral;
      break;
    case 5:
      mensaje = EtiquetasGenerales.etiqueta_general_ayuda_seundo_resGral;
      break;
    case 6:
      mensaje = EtiquetasGenerales.etiqueta_general_ayuda_incidentes_resGral;
      break;
    case 7:
      mensaje = EtiquetasGenerales.etiqueta_general_calculo_primer_reporte_concluido_resGral;
      break;
    case 8:
      mensaje = "";
      break;
    default:
  }

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Acerca del cálculo</Modal.Title>
      </Modal.Header>
      <Modal.Body>{nl2br(mensaje)}</Modal.Body>
    </Modal>
  );
};

export default ModalInformativo;

import React, { Component } from "react";
//Bootstrap React
import { Container, Row, Col, Nav, NavDropdown, Button } from "react-bootstrap";
import { EnlacesGenerales } from "../../Constants/ConstantesGlobales";
//import jsonMenu from '../../ Assets/json/Menu/Menu.json';
import jsonMenu from '../../Assets/json/Menu/Menu.json';
import { Link } from 'react-router-dom';

class Menu extends Component {
  constructor() {
    super();
    this.state = {
      data: jsonMenu.secciones,
      path: null
    };
  }

  render() {
    if (!this.state.data) {
      return <div>Loading...</div>;
    }

    return (
      <div className="navMenu ocultarMovil">
        <Container>
          <Row>
            <Col xs={10} md={9}>
              <Nav activeKey="1" id="menuNavegacion">
                <a href="/" className="inicio">
                  <i className="fa fa-home"></i>
                </a>
                {this.state.data.map((info, index) => (
                  <NavDropdown
                    key={index}
                    title={info.descripcionSeccion}
                    id="nav-dropdown"
                  >
                    {renderListItems(info)}
                  </NavDropdown>
                ))}
              </Nav>
            </Col>
            <Col xs={1} md={1}>
              <div className="GlosarioNav">
                <Button border="0px;" className="btnUlises nav-link" variant="contained" href="/Glosario">
                  Glosario
                </Button>
              </div>
            </Col>
            <Col xs={1} md={2}>
              <div className="ayudaNav">
                <a href={EnlacesGenerales.centro_ayuda} target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-question-circle question"></i>Ayuda
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const renderListItems = props => {
  var items = [];
  for (var i = 0; i < props.nivelNavegacion.length; i++) {
    var nombreNivel = props.nivelNavegacion[i].descripcionNivel;
    var ruta = props.nivelNavegacion[i].rutaMenu;
    var idSeccion = props.idSeccion;
    var idNivel = props.nivelNavegacion[i].idNivel;
    items.push(
      <NavDropdown.Item key={i} eventKey="1" href={`/${ruta}/${idSeccion}/${idNivel}`} >
        {nombreNivel}
      </NavDropdown.Item>
    );
  }
  return items;
};

export default Menu;

import React from "react";
import { Modal } from "react-bootstrap";

const ModalEmergente = props => {
  const title = props.children ? props.children[0] : '';
  const children = props.children && props.children.length > 1 ? [...props.children].slice(1) : [];

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ModalEmergente;

//Regresa formato 000,000
export const formatNumber = numOriginal => {
  var final =  numOriginal;
  var resultado = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return resultado;
};

//Regresa formato 000,000
export const formatSix = numOriginal => {
  var longuitud = numOriginal.toString();
  var tam = longuitud.length;
  var addCeros = "";
  for (var i = tam; i < 6; i++) {
    addCeros = addCeros + "0";
  }
  var final = addCeros + numOriginal;
  var resultado = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return resultado;
};

//Regresa formato 00,000
export const formatFive = numOriginal => {
    var longuitud = numOriginal.toString();
    var tam = longuitud.length;
    var addCeros = "";
    for (var i = tam; i < 5; i++) {
      addCeros = addCeros + "0";
    }
    var final = addCeros + numOriginal;
    var resultado = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return resultado;
  };

//Regresa formato 0,000
export const formatFour = numOriginal => {
  var longuitud = numOriginal.toString();
  var tam = longuitud.length;
  var addCeros = "";
  for (var i = tam; i < 4; i++) {
    addCeros = addCeros + "0";
  }
  var final = addCeros + numOriginal;
  var resultado = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return resultado;
};

//Regresa formato 000
export const formatThree = numOriginal => {
  var longuitud = numOriginal.toString();
  var tam = longuitud.length;
  var addCeros = "";
  for (var i = tam; i < 3; i++) {
    addCeros = addCeros + "0";
  }
  var final = addCeros + numOriginal;
  var resultado = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return resultado;
};

//Regresa formato porcentaje 00.00
export const formatPorcentaje = numOriginal => {
  var final = numOriginal;
  var resultado = "";
  if (final !== 100){
    resultado = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }else{
    resultado = "100";
  }

  
  return resultado;
};

export const formatPorcentajeDoble = numOriginal => {
  var longuitud = numOriginal.toString();
  longuitud = longuitud.replace(".", "");
  var tam = longuitud.length;
  var addCeros = "";
  for (var i = tam; i < 4; i++) {
    addCeros = addCeros + "0";
  }
  var final = addCeros + numOriginal;
  var resultado = final.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ".");
  return resultado;
};


export const formatPorcentajeDoubleEs = numOriginal => {
  
  var final = numOriginal;
  var resultado = "";
 
    resultado = Number(numOriginal).toFixed(2);
    resultado.toString();

  return resultado;
};

export const formatPorcentajeFirst = numOriginal => {
  
  var final = numOriginal;
  var resultado = "";
 
    resultado = Number(numOriginal).toFixed(0);
    resultado.toString();

  return resultado;
};
import React, { useCallback, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
//Rutas
import Home from "../Components/Home";
//ResumenGeneral
import ResumenGeneralNacional from "../Views/ResumenGeneral/ResumenGeneralNacional";
import ResumenGeneralEntidad from "../Views/ResumenGeneral/ResumenGeneralEntidad";
//Instalacion Casillas
import InstalacionCasillasNacional from "../Views/InstalacionCasillas/InstalacionCasillasNacional";
import InstalacionCasillasEntidad from "../Views/InstalacionCasillas/InstalacionCasillasEntidad";
import InstalacionCasillasDistritosFederales from "../Views/InstalacionCasillas/InstalacionCasillasDistritosFederales";
//Segundo Reporte
import SegundoReporteNacional from "../Views/SegundoReporte/SegundoReporteNacional";
import SegundoReporteEntidad from "../Views/SegundoReporte/SegundoReporteEntidad";
import SegundoReporteDistritosFederales from "../Views/SegundoReporte/SegundoReporteDistritosFederales";
//Incidentes
import IncidentesNacional from "../Views/Incidentes/IncidentesNacional";
import IncidentesEntidad from "../Views/Incidentes/IncidentesEntidad";
//Glosario
import Glosario from "../Views/Glosario/Glosario";

import Menu from "../Components/Plantilla/Menu";
import Header from "../Components/Plantilla/Header";
import Footer from "../Components/Plantilla/Footer";
import SinInfo from "../Components/Plantilla/SinInfo";

const Rutas = () => {
  //////////// CORS ////////////

  var refreshTime = 60000; //60000 1 min, 300000 5 min
  var myHeaders = new Headers();

  myHeaders.append("X-Content-Type-Options", "nosniff");
  myHeaders.append("X-Frame-Options", "deny");
  myHeaders.append("X-XSS-Protection", "1;mode=block");
  myHeaders.append("Cache-Control", "no-cache, no-store, must-revalidate");

  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append(
    "Access-Control-Allow-Headers",
    "Origin, X-Requested-With, Content-Type, Accept"
  );

  var headers = {
    method: "GET",
    headers: myHeaders
  };

  //////////////////////////////

  var eltimer = setInterval(refreshPage, refreshTime);

  const [bandera, setBandera] = useState(true);

  fetch("/json/Secciones/Configuracion.json", headers)
    .then(resolve => {
      return resolve.json();
    })
    .then(info => {
      setBandera(info.portalActivo);
    })
    .catch(error => {
      console.log("El error es : ", error);
    });

  if (bandera == true) {
    return (
      <div>
        <Header></Header>
        <Menu></Menu>
        <Switch>
          <Redirect
            exact
            from="/"
            to="/ResumenGeneralNacional/1/1"
            component={Home}
          />
          <Route
            path="/ResumenGeneralNacional/:id/:id"
            component={ResumenGeneralNacional}
          ></Route>
          <Route
            path="/ResumenGeneralEntidad/:id/:id"
            component={ResumenGeneralEntidad}
          ></Route>
          <Route
            path="/InstalacionCasillasNacional/:id/:id"
            component={InstalacionCasillasNacional}
          ></Route>
          <Route
            path="/InstalacionCasillasEntidad/:id/:id"
            component={InstalacionCasillasEntidad}
          ></Route>
          <Route
            path="/InstalacionCasillasDistritosFederales/:id/:id"
            component={InstalacionCasillasDistritosFederales}
          ></Route>
          <Route
            path="/SegundoReporteNacional/:id/:id"
            component={SegundoReporteNacional}
          ></Route>
          <Route
            path="/SegundoReporteEntidad/:id/:id"
            component={SegundoReporteEntidad}
          ></Route>
          <Route
            path="/SegundoReporteDistritosFederales/:id/:id"
            component={SegundoReporteDistritosFederales}
          ></Route>
          <Route
            path="/IncidentesNacional/:id/:id"
            component={IncidentesNacional}
          ></Route>
          <Route
            path="/IncidentesEntidad/:id/:id"
            component={IncidentesEntidad}
          ></Route>
          <Route
            path="/Glosario"
            component={Glosario}
          ></Route>
        </Switch>
        <Footer></Footer>
      </div>
    );
  }

  if (bandera == false) {
    return (
      <div>
        <Header></Header>
        <SinInfo></SinInfo>        
      </div>
    );
  }
};

function refreshPage() {
  window.location.reload();
}

export default Rutas;

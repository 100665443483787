import React, { useState } from "react";
import nl2br from "react-newline-to-break";

//Constants
import { EtiquetasGenerales } from "../../Constants/ConstantesGlobales";
//Auxiliares
import { formatNumber, formatPorcentajeDoubleEs, formatPorcentajeFirst } from "../Auxiliares/ConvertNumber";
import ModalInformativo from '../ResumenGeneral/ModalInformativoResumen';


const getEscalasGrafica = (totalVotos, numeroEscalas) => {

  var escalasGrafica = [];
  for (var porcentaje = 0; porcentaje <= numeroEscalas; porcentaje++) {
    var auxEscala = (totalVotos / numeroEscalas) * porcentaje;
    var tmpPorcentaje =
      porcentaje !== 0
        ? ((totalVotos - (totalVotos % numeroEscalas)) / numeroEscalas) *
        porcentaje
        : 0;
    // if (tmpPorcentaje === 0 && porcentaje !== 0)
    tmpPorcentaje = formatPorcentajeFirst(auxEscala);
    escalasGrafica.push({
      escala: tmpPorcentaje.toString(),
      porcentaje: porcentaje * numeroEscalas
    });
  }
  return escalasGrafica;
};

const obtenerAlturaBarra = (porcentaje, total, casillasInf) => {
  let val = 0;

  if (porcentaje <= 100) {
    val = porcentaje;
  } else {
    if (casillasInf != 0) {
      val = (total / casillasInf) * 100;

      if (val > 100) {
        val = 100;
      }
    }
  }

  return val;
}
const renderBarraConPorcentaje = (index, porcentaje, total, casillasInf) => {
  let alturaPorcentaje = obtenerAlturaBarra(porcentaje, total, casillasInf);

  return (
    <td key={index}>
      <div
        className="barra conPorcentaje"
        style={{
          height: alturaPorcentaje + '%'
        }}
        data-value={formatPorcentajeDoubleEs(alturaPorcentaje)}
      ></div>
    </td>
  )
}

const GraficaCasillasInstaladas = props => {

  const { totalCasillas, dataGrafica, descripcionEjeY, labelSuperior, mensajeModal, casillasInferior, labelInferior } = props;
  const [showModal, setShowModal] = useState(false);
  const data = dataGrafica;
  var escalasGrafica = [];
  escalasGrafica = getEscalasGrafica(casillasInferior, 10);

  return (
    <div className="contenido borde">
      <div>
        <p className="encabezadoGraficasBarra">
          {/* {EtiquetasGenerales.etiqueta_general_total}: */}
          <span className="enfasis2">
            {" "}
            {formatNumber(totalCasillas)}{" "}
          </span>
          <span>{labelSuperior}</span>
          {/* <span
            onClick={() => setShowModal(!showModal)}
            className="quitarSubrayado"
          >
            <i className="fas fa-question-circle info"></i>
          </span> */}
        </p>
      </div>

      <div className="contenedorGraficas">
        <div className="centraContenido">
          <div className="contenidoGraphBarras">
            <div className="descriptionEjeY">{descripcionEjeY}</div>
            <table className="partidoTabla">
              <thead>
                <tr>
                  <td>
                    <div className="valorY">
                      {100/*formatNumber(escalasGrafica[10].escala)*/}%
                    </div>
                    <div className="valorY">
                      {90/*formatNumber(escalasGrafica[9].escala)*/}%
                    </div>
                    <div className="valorY">
                      {80/*formatNumber(escalasGrafica[8].escala)*/}%
                    </div>
                    <div className="valorY">
                      {70/*formatNumber(escalasGrafica[7].escala)*/}%
                    </div>
                    <div className="valorY">
                      {60/*formatNumber(escalasGrafica[6].escala)*/}%
                    </div>
                    <div className="valorY">
                      {50/*formatNumber(escalasGrafica[5].escala)*/}%
                    </div>
                    <div className="valorY">
                      {40/*formatNumber(escalasGrafica[4].escala)*/}%
                    </div>
                    <div className="valorY">
                      {30/*formatNumber(escalasGrafica[3].escala)*/}%
                    </div>
                    <div className="valorY">
                      {20/*formatNumber(escalasGrafica[2].escala)*/}%
                    </div>
                    <div className="valorY">
                      {10/*formatNumber(escalasGrafica[1].escala)*/}%
                    </div>
                  </td>
                  {data.map((info, index) => renderBarraConPorcentaje(index, info.porcentaje, info.total, casillasInferior)
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pieDeBarra"></td>
                  {data.map((info, index) => (
                    <td key={index}>{info.nombre}{nl2br("\n")}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <p className="encabezadoGraficasBarra">
        <span>de </span>
        <b>
          {" "}
          {formatNumber(casillasInferior)}{" "}
        </b>
        <span>{labelInferior}</span>
        <span
          onClick={() => setShowModal(!showModal)}
          className="quitarSubrayado"
        >
          <i className="fas fa-question-circle info"></i>
        </span>
      </p>

      {/* Modal */}
      <ModalInformativo
        show={showModal}
        onHide={() => setShowModal(false)}
        path={props.path}
        mensaje={mensajeModal}
      ></ModalInformativo>


    </div>
  );
};

export default GraficaCasillasInstaladas;

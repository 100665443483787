import React, { useCallback, useState } from "react";
//Constant
import { EtIncidentes } from "../../Constants/ConstantesGlobales";
import ModalIncidentes from "./ModalIncidentes";
import { Col } from "react-bootstrap";

const ListaIncidentes = props => {

  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState("");

  const handleIncrementClick = useCallback(info => {
    setShowModal(!showModal);
    setDataModal(info);
  }, []);

  const info = {
    incidentes: props.data.todosIncidentes
  };
  return (
    <div className="contenido borde">
      <Col xs={12} md={12}>
        <h2>{EtIncidentes.incidentes_reportados}</h2>
        {info.incidentes.map((info, index) => (
          <div key={index} className="incidentesIndividuales" type="button" onClick={() => handleIncrementClick(info)}>
              {info.inciso}
          </div>
        ))}
      </Col>
      <Col md={12}>
        <a href="https://intranet.ine.mx/tutoriales/sistemas/Electorales/2019-2020/Local/PortalSIJE/#/CatalogoIncidentes" target="_blank" rel="noopener noreferrer" className="vinculo">Catálogo de incidentes SIJE</a>
      </Col>

      {/* Modal */}
      
      <ModalIncidentes
        show={showModal}
        onHide={() => setShowModal(false)}
        data={dataModal}
      ></ModalIncidentes>
    </div>
  );
};

export default ListaIncidentes;

import React, { useState } from "react";
//Plugins
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col } from "react-bootstrap";

//Components
import Botones from '../Genericos/Botones';
import { EtiquetasBotones } from "../../Constants/ConstantesGlobales";
//Imagenes
import CasillaInst from "../../Assets/images/ico_casillaInst.svg";
import CasillaNoInst from "../../Assets/images/ico_casillaNOInst.svg";
import Junta from "../../Assets/images/Junta.svg";

// Modal informativo
import ModalInformativo from '../Genericos/ModalInformativo';

//Auxiliares
import { formatNumber, formatPorcentaje, formatPorcentajeDoubleEs } from "../Auxiliares/ConvertNumber";

const GraficaCasillasIntaladasPie = props => {

  const [showModalDos, setShowModalDos] = useState(false);
  const [showModalTres, setShowModalTres] = useState(false);
  const [showModalCuatro, setShowModalCuatro] = useState(false);
  const [showModalCinco, setShowModalCinco] = useState(false);

  const idEntidad = props.data.id;
  const pieSeccion_01 = props.data.instalacion;
  const pieSeccion_02 = props.data.segundo;
  const pieSeccion_03 = props.data.incidentes;
  let pieSeccion_04 = {};
  const tReportadas = props.data.instalacion.totalCasillasReportadas;
  const pRepresentantes = props.data.porcentajeRepresentantes && props.data.porcentajeRepresentantes.porcentajeRepresentantes != null ? props.data.porcentajeRepresentantes.porcentajeRepresentantes : 0.00;
  const pMDCCompleta = props.data.porcentajeMDCCompleta && props.data.porcentajeMDCCompleta.porcentajeMDCCompleta ? props.data.porcentajeMDCCompleta.porcentajeMDCCompleta : 0.00;


  props.data.grafica.map((info, index) => {
    if (info.nombre === "Casillas con primer reporte concluido") {
      console.log("Info: ", info)
      pieSeccion_04 = info;
    }
  })

  var faltantesUno;
  var faltantesDos;
  var faltantesTres;
  var faltantesCuatro;

  if ((100 - pieSeccion_01.porcentajeCasillasReportadas) === 0) {
    faltantesUno = null;
  } else {
    faltantesUno = 100 - pieSeccion_01.porcentajeCasillasReportadas;
  }

  if ((100 - pieSeccion_02.porcentajeCasillasSegundo) === 0) {
    faltantesDos = null;
  } else {
    faltantesDos = 100 - pieSeccion_02.porcentajeCasillasSegundo;
  }

  if ((100 - pieSeccion_03.porcentajeCasillasIncidente) === 0) {
    faltantesTres = null;
  } else {
    faltantesTres = 100 - pieSeccion_03.porcentajeCasillasIncidente;
  }

  if ((100 - pieSeccion_04.porcentaje) === 0) {
    faltantesCuatro = null;
  } else {
    faltantesCuatro = 100 - pieSeccion_04.porcentaje;
  }

  //const tablaSeccion_01 = props.data.incidentes.top5nIncidentes;

  const optionsGraficaUno = {
    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      renderTo: "container",
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#bf6da0", "#ececec"],
        dataLabels: {
          enabled: false
        }
      },
      states: {
        hover: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
    },

    series: [
      {
        name: "Total",
        data: [
          { name: "Instaladas", y: pieSeccion_01.porcentajeCasillasReportadas },
          {
            name: "Faltantes",
            y: faltantesUno
          }
        ]
      }
    ]
  };

  const optionsGraficaDos = {
    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      renderTo: "container",
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#bf6da0", "#ececec"],
        dataLabels: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
    },

    series: [
      {
        name: "Total",
        data: [
          { name: "Con Segundo reporte", y: pieSeccion_02.porcentajeCasillasSegundo },
          {
            name: "Faltantes",
            y: faltantesDos
          }
        ]
      }
    ]
  };

  const optionsGraficaTres = {
    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      renderTo: "container",
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#bf6da0", "#ececec"],
        dataLabels: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
    },

    series: [
      {
        name: "Total",
        data: [
          { name: "Con incidentes", y: pieSeccion_03.porcentajeCasillasIncidente },
          {
            name: "Sin incidentes",
            y: faltantesTres
          }
        ]
      }
    ]
  };


  const optionsGraficaCuatro = {
    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      renderTo: "container",
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#bf6da0", "#ececec"],
        dataLabels: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
    },

    series: [
      {
        name: "Total",
        data: [
          { name: "Con Primer reporte concluido", y: pieSeccion_04.porcentaje },
          {
            name: "Faltantes",
            y: faltantesCuatro
          }
        ]
      }
    ]
  };

  return (
    <div className="contenido">

      {/* Titulos */}
      <Row>

        {/* Instalación de casillas */}
        <Col xs={12} xl={4} sm={4} md={4} lg={4}>


          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="title-res-gral-casillas">
            <h2>
              Instalación de casillas{" "}

              <span
                onClick={() => setShowModalDos(!showModalDos)}
                className="quitarSubrayado"
              >
                <i className="fas fa-question-circle info"></i>
              </span>

            </h2>
          </Col>

          {/* Instalación de casillas gráfica de PIE */}
          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="graph-pie-res-gral">
            <div className="graficaPie">
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { height: "100%", width: "100%" } }}
                options={optionsGraficaUno}
              />
            </div>
            <div>
              <p className="enfasis1">
                {formatPorcentajeDoubleEs(pieSeccion_01.porcentajeCasillasReportadas)}%
                </p>
              <p>
                <strong>{formatNumber(pieSeccion_01.totalCasillasReportadas)}</strong>{" "}
                  casillas reportadas
                </p>

              {/* <hr className="lineReportadas" />
              <p>
                <strong>{formatNumber(pieSeccion_01.totalCasillasReportadas)}</strong>{" "}
                  casillas reportadas
                </p> */}

            </div>
          </Col>


          {/* Instalación de casillas - CASILLAS */}
          <Row className="casillas-heigth-res-gral">
            <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-center">
              <img src={CasillaInst} alt="Casillas instaladas" />
              <p className="enfasis2">{formatPorcentajeDoubleEs(pieSeccion_01.porcentajeCasillasInstaladas)}%</p>
              <p className="enfasis2">{formatNumber(pieSeccion_01.totalCasillasInstaladas)}</p>
              <p>casillas instaladas</p>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-center bordeLateral">
              <img src={CasillaNoInst} alt="Casillas no instaladas" />
              <p className="enfasis2">{formatPorcentajeDoubleEs(pieSeccion_01.porcentajeCasillasNoInstaladas)}%</p>
              <p className="enfasis2">
                {formatNumber(pieSeccion_01.totalCasillasNoInstaladas)}</p>
              <p>
                casillas no instaladas
            </p>
            </Col>
          </Row>


          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="btns-enlaces-resumen-gral margen-btns-casillas-res-gral">
            <Botones
              tipoBoton="3"
              etiqueta={EtiquetasBotones.etiqueta_boton_instalacion_casillas}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>

        </Col>


        {/* Primer reporte concluido. */}
        <Col xs={12} xl={4} sm={4} md={4} lg={4} className="bordeLateral">
          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="title-res-gral-casillas">
            <h2>
              Primer reporte concluido{" "}

              <span
                onClick={() => setShowModalCinco(!showModalCinco)}
                className="quitarSubrayado"
              >
                <i className="fas fa-question-circle info"></i>
              </span>

            </h2>
          </Col>

          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="graph-pie-res-gral">
            <div className="graficaPie">
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { height: "100%", width: "100%" } }}
                options={optionsGraficaCuatro}
              />
            </div>
            <div>
              <p className="enfasis1">
                {formatPorcentajeDoubleEs(pieSeccion_04.porcentaje)}%
                </p>
              <p>
                <strong>{formatNumber(pieSeccion_04.total == undefined ? 0 : pieSeccion_04.total)}</strong>{" "}
                  casillas con primer reporte concluido
                </p>
            </div>
          </Col>

          <Row className="casillas-heigth-res-gral">
            <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-center">
              <img src={Junta} alt="Casillas instaladas" width={60} height={31} />
              <p className="enfasis2">{formatPorcentajeDoubleEs(pMDCCompleta)}%</p>
              <p>casillas instaladas con Mesa Directiva de Casilla completa</p>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-center bordeLateral">
              <i className="fa fa-user iconosPurple"></i>
              <p className="enfasis2">
                {formatPorcentajeDoubleEs(pRepresentantes)}%</p>
              <p>
                casillas con Representantes PP/CI
            </p>
            </Col>
          </Row>

          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="btns-enlaces-resumen-gral margen-btns-casillas-res-gral">
            <Botones
              tipoBoton="3"
              etiqueta={EtiquetasBotones.etiqueta_boton_instalacion_casillas}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>

        </Col>

        {/* Segundo reporte */}
        <Col xs={4} xl={4} sm={4} md={4} lg={4} className="bordeLateral">
          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="title-res-gral-casillas">
            <h2>
              Segundo Reporte{" "}

              <span
                onClick={() => setShowModalTres(!showModalTres)}
                className="quitarSubrayado"
              >
                <i className="fas fa-question-circle info"></i>
              </span>

            </h2>

          </Col>


          {/* Segundo reporte grafica PIE */}
          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="graph-pie-res-gral">
            <div className="graficaPie">
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { height: "100%" } }}
                options={optionsGraficaDos}
              />
            </div>
            <div>
              {pieSeccion_02.porcentajeCasillasSegundo != null ?
                (
                  <p className="enfasis1">
                    {formatPorcentajeDoubleEs(pieSeccion_02.porcentajeCasillasSegundo)}%
                  </p>)
                : (<p className="enfasis1">
                  {formatPorcentajeDoubleEs(0)}%
                </p>)
              }
              <p>
                <strong>{formatNumber(pieSeccion_02.totalCasillasSegundo)}</strong> casillas
                  con segundo reporte
                </p>
            </div>
          </Col>

          {/* Casillas segundo reporte */}
          <Row className="casillas-heigth-res-gral">
            <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-center">
              <i className="fa fa-eye iconosPurple"></i>
              <p className="enfasis2">
                {formatPorcentajeDoubleEs(pieSeccion_02.porcentajeCasillasObservadores)}%
            </p>
              <p>casillas con Observadores electorales</p>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-center bordeLateral">
              <i className="fa fa-user iconosPurple"></i>
              <p className="enfasis2">
                {formatPorcentajeDoubleEs(pieSeccion_02.porcentajeCasillasRepresentantes)}%
            </p>
              <p>casillas con Representantes PP / CI</p>
            </Col>
          </Row>

          <Col xs={12} xl={12} sm={12} md={12} lg={12} className="btns-enlaces-resumen-gral margen-btns-casillas-res-gral">
            <Botones
              tipoBoton="4"
              etiqueta={EtiquetasBotones.etiqueta_boton_segundo_reporte}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>

        </Col>

      </Row>

      {/* Instalacion Casillas */}

      {/* <div className="mb-2">
        <Row className="lineaMedia">
          <h2>
            Instalación de casillas{" "}

            <span
            onClick={() => setShowModalDos(!showModalDos)}
            className="quitarSubrayado"
          >
            <i className="fas fa-question-circle info"></i>
          </span>

          </h2>
        </Row>

        <Row>
          <Col xs={12} md={6} sm={12}>
            <Row>
              <div className="graficaPie">
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { height: "100%", width: "100%" } }}
                  options={optionsGraficaUno}
                />
              </div>
              <div>
                <p className="enfasis1">
                  {formatPorcentajeDoubleEs(pieSeccion_01.porcentajeCasillasInstaladas)}%
                </p>
                <p>
                  <strong>{formatNumber(pieSeccion_01.totalCasillasInstaladas)}</strong>{" "}
                  casillas instaladas
                </p>
                <hr className="lineReportadas" />
                <p>
                  <strong>{formatNumber(pieSeccion_01.totalCasillasReportadas)}</strong>{" "}
                  casillas reportadas
                </p>

              </div>
            </Row>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={3} className="text-center bordeLateral">
            <img src={CasillaInst} alt="Casillas instaladas" />
            <p className="enfasis2">{formatNumber(pieSeccion_01.totalCasillasPrimRepConc)}</p>
            <p>casillas con primer reporte concluido</p>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={3} className="text-center bordeLateral">
            <img src={CasillaNoInst} alt="Casillas no instaladas" />
            <p className="enfasis2">
              {formatNumber(pieSeccion_01.totalCasillasNoInstaladas)}</p>
              <p>
              casillas no instaladas
            </p>
          </Col>
        </Row>

        <Row className="mb-2 mt-2">
          <Col>
            <Botones
              tipoBoton="3"
              etiqueta={EtiquetasBotones.etiqueta_boton_instalacion_casillas}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>
        </Row>
      </div> */}

      {/* Segundo Reporte */}

      {/* <div className="mb-2">
        <Row className="lineaMedia">
          <h2>
            Segundo Reporte{" "}
            
            <span
            onClick={() => setShowModalTres(!showModalTres)}
            className="quitarSubrayado"
          >
            <i className="fas fa-question-circle info"></i>
          </span>

          </h2>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Row>
              <div className="graficaPie">
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { height: "100%" } }}
                  options={optionsGraficaDos}
                />
              </div>
              <div>
                <p className="enfasis1">
                  {formatPorcentajeDoubleEs(pieSeccion_02.porcentajeCasillasSegundo)}%
                </p>
                <p>
                  <strong>{formatNumber(pieSeccion_02.totalCasillasSegundo)}</strong> casillas
                  con segundo reporte
                </p>
              </div>
            </Row>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={3} className="text-center bordeLateral">
            <i className="fa fa-eye iconosPurple"></i>
            <p className="enfasis2">
              {formatPorcentajeDoubleEs(pieSeccion_02.porcentajeCasillasObservadores)}%
            </p>
            <p>casillas con Observadores electorales</p>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={3} className="text-center bordeLateral">
            <i className="fa fa-user iconosPurple"></i>
            <p className="enfasis2">
              {formatPorcentajeDoubleEs(pieSeccion_02.porcentajeCasillasRepresentantes)}%
            </p>
            <p>casillas con Representantes PP / CI</p>
          </Col>
        </Row>

        <Row className="mb-2 mt-2">
          <Col>
            <Botones
              tipoBoton="4"
              etiqueta={EtiquetasBotones.etiqueta_boton_segundo_reporte}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>
        </Row>
      </div> */}

      {/* Incidentes */}

      {/*}  <div className="mb-2">
        <Row className="lineaMedia">
          <h2>
            Incidentes

            <span
            onClick={() => setShowModalCuatro(!showModalCuatro)}
            className="quitarSubrayado"
          >
            <i className="fas fa-question-circle info"></i>
          </span>

          </h2>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Row>
              <div className="graficaPie">
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { height: "100%" } }}
                  options={optionsGraficaTres}
                />
              </div>
              <div>
                <p className="enfasis1">
                  {formatPorcentajeDoubleEs(pieSeccion_03.porcentajeCasillasIncidente)}%
                </p>
                <p>
                  <strong>{formatNumber(pieSeccion_03.totalCasillasIncidente)}</strong>{" "}
                  casillas con incidente reportado
                </p>
              </div>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center bordeLateral">
            <i className="fa fa-bullhorn iconosPurple"></i>
            <p className="enfasis2">{formatNumber(pieSeccion_03.totalIncidentes)}</p>
            <p>incidentes reportados</p>
          </Col>
        </Row>
        <Row className="mb-2 mt-2">
          <Col>
           {/* <Botones
              tipoBoton="5"
              etiqueta={EtiquetasBotones.etiqueta_boton_incidentes}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>
        </Row>
          </div> */}

      {/* Modal */}
      <ModalInformativo
        show={showModalDos}
        onHide={() => setShowModalDos(false)}
        path={'/instalacion/4'}
      ></ModalInformativo>

      {/* Modal 2 */}
      <ModalInformativo
        show={showModalTres}
        onHide={() => setShowModalTres(false)}
        path={'/segundo/5'}
      ></ModalInformativo>

      {/* Modal 3 */}
      <ModalInformativo
        show={showModalCuatro}
        onHide={() => setShowModalCuatro(false)}
        path={'/incidente/6'}
      ></ModalInformativo>

      {/* Modal Primer reporte concluido */}
      <ModalInformativo
        show={showModalCinco}
        onHide={() => setShowModalCinco(false)}
        path={'/incidente/7'}
      ></ModalInformativo>

    </div >
  );
};

export default GraficaCasillasIntaladasPie;

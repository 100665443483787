import React, { useCallback, useState } from "react";

//Constantes
import { EtiquetasGenerales } from "../../Constants/ConstantesGlobales";
//Plugins, components
import { Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
//Auxiliares
import { formatNumber, formatPorcentajeDoubleEs } from "../Auxiliares/ConvertNumber";
import { color } from "highcharts";



const RepresentantesPPCIInformacion = props => {

  const info = {
    data: props.data.representantesInformacion.sort((a, b) => { return a.orden - b.orden })
  };


  const renderListItems = (props, index) => {
    return (
      <div key={index} className="repreInformacionMasItem">

        <p >
          <img className="representantesPPCI-emblema" src={require(`../../Assets/images/ppci/${props.asoc}`)} />
        </p>

        <div >
          <div>
            <p >
              <strong>{formatNumber(props.total)}</strong>
            </p>
          </div>
          <p>
            <strong>{formatPorcentajeDoubleEs(props.porcentaje)}%</strong>
          </p>
        </div>
      </div>
    );
  };

  return (

    <div className="contenido borde">
      <Col xs={12} md={12}>
        <h2>Representantes de Partidos Políticos y Candidaturas Independientes</h2>

        <div className="representantesInfoDistribucion">
          <div >
            {info.data.map((info, index) => renderListItems(info, index))}
          </div>
        </div>
        <div className="required" >
          El porcentaje se obtiene dividiendo el total de las y los representantes de cada partido o candidatura independiente, entre el total de las y los representantes que han asistido, por cien.
        </div>
      </Col>

    </div>
  );
};

export default RepresentantesPPCIInformacion;



import React from "react";
//Plugins
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col } from "react-bootstrap";
import {formatNumber, formatPorcentajeDoubleEs} from "../Auxiliares/ConvertNumber";

const TipoAplicativo = props => {

  const infoWeb = props.data.reportesAplicativo;
  const infoApp = props.data.reportesAplicativo;
  const titulo = props.title;


  const graficaAplicativoWeb = {

    credits: {
      enabled: false
    },

    colors: ['#BF6DA0', '#ccc'],

    chart: {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: -50,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      
    },

    title: {
      text: formatPorcentajeDoubleEs(infoWeb.SIJEWeb.porcentaje) + "%",
      align: "center",
      verticalAlign: "middle",
      y: 40,
      style: {
        fontWeight: "bold"
      }
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>"
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        dataLabels: {
          enabled: true,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white"
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "100%"
      }
    },
    series: [
      {
        type: "pie",
        name: "Total",
        innerSize: "70%",
        data: [
          ["", infoWeb.SIJEWeb.porcentaje],
          ["", 100 - infoWeb.SIJEWeb.porcentaje]
        ]
      }
    ]
  };


  const graficaAplicativoApp = {

    credits: {
      enabled: false
    },

    colors: ['#BF6DA0', '#ccc'],

    chart: {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: -50,
      spacingLeft: 0,
      spacingRight: 0,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false
    },
    title: {
      text: formatPorcentajeDoubleEs(infoApp.SIJEApp.porcentaje) + "%",
      align: "center",
      verticalAlign: "middle",
      y: 40,
      style: {
        fontWeight: "bold"
      }
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>"
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        dataLabels: {
          enabled: true,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white"
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "100%"
      }
    },
    series: [
      {
        type: "pie",
        name: "Total",
        innerSize: "70%",
        data: [
          ["", infoApp.SIJEApp.porcentaje],
          ["", 100 - infoApp.SIJEApp.porcentaje]
        ]
      }
    ]
  };


  return (
    <div className="contenido">
      <h2>{(titulo)}</h2>
      <Row className="reportesPorTipo">
        <Col xs={12} xl={6} sm={6} md={6} lg={6}>
          <h5><i className="fa fa-desktop"></i> SIJE web</h5>
          <div className="graficaPie">
            <HighchartsReact
              highcharts={Highcharts}
              containerProps={{ style: { height: "100%" } }}
              options={graficaAplicativoWeb}
            />
          </div>
          <div className="text-left-pie bordeLateral">
            <p className="enfasis2">{formatNumber(infoWeb.SIJEWeb.total)}</p>
            <p className="preludio2">casillas reportadas</p>
          </div>
        </Col>
        <Col xs={12} xl={6} sm={6} md={6} lg={6}>
          <h5><i className="fas fa-mobile-alt"></i> SIJE app</h5>
          <div className="graficaPie">
            <HighchartsReact
              highcharts={Highcharts}
              containerProps={{ style: { height: "100%" } }}
              options={graficaAplicativoApp}
            />
          </div>
          <div className="text-left-pie bordeLateral">
            <p className="enfasis2">{formatNumber(infoApp.SIJEApp.total)}</p>
            <p className="preludio2">casillas reportadas</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TipoAplicativo;

import React from "react";

export const ContenedorDefinicion = props => {
  const { titulo, children, className } = props;
  
  return (
    <div className={className}>
      <span className="modal-emergente-contenido-titulo">{titulo} </span>
      <span className="modal-emergente-contenido-cuerpo">{children}</span>
    </div>
  );
}

export const DefCasillasInstaladas = props => {
  return (
    <ContenedorDefinicion
      {...props}
      titulo="Casillas instaladas.">
      El porcentaje se calcula como el número
      absoluto de casillas con Hora de Instalación,
      dividido entre el número de casillas reportadas,
      por cien.
    </ContenedorDefinicion>
  );
}

export const DefCasillasNoInstaladas = props => {
  return (
    <ContenedorDefinicion
      {...props}
      titulo="Casillas no instaladas.">
      El porcentaje se calcula como el número absoluto de
      casillas reportadas como no instaladas,
      dividido entre el número de casillas reportadas,
      por cien.
    </ContenedorDefinicion>
  );
}

export const DefCasillasPrimerReporteConcluido = props => {
  return (
    <ContenedorDefinicion
      {...props}
      titulo="Casillas con primer reporte concluido.">
      El porcentaje se calcula como el número absoluto de
      casillas con primer reporte concluido,
      dividido entre el número de casillas instaladas, por cien.
    </ContenedorDefinicion>
  );
}

export const DefCasillasSegundoReporte = props => {
  return (
    <ContenedorDefinicion
      {...props}
      titulo="Casillas con segundo reporte.">
      El porcentaje se calcula como el número absoluto de
      casillas con segundo reporte, dividido entre el
      número de casillas con primer reporte concluido, por cien.
    </ContenedorDefinicion>
  );
}

export const DefCasillasObservadoresElectorales = props => {
  return (
    <ContenedorDefinicion
      {...props}
      titulo="Casillas con Observadores electorales.">
      El porcentaje se calcula con el número absoluto de casillas
      reportadas con observadores electorales, dividido entre el
      número de casillas con segundo reporte por cien.
    </ContenedorDefinicion>
  );
}

export const DefCasillasRepresentantesPPCI = props => {
  return (
    <ContenedorDefinicion
      {...props}
      titulo="Casillas con Representantes PP / CI.">
      El porcentaje se calcula como el número de casillas 
      con presencia de representantes propietarios, 
      suplentes o generales, según partido político nacional, 
      partido político local o candidatura independiente, 
      dividido entre el número de casillas con segundo 
      reporte, por cien.
    </ContenedorDefinicion>
  );
}

export const DefCasillasMayorMenorAvance = props => {
  return (
    <ContenedorDefinicion
      {...props}
      titulo="Mayor y Menor avance." >.
      El porcentaje se calcula como el número absoluto de
      casillas instaladas en la entidad,
      dividido entre el número de casillas aprobadas en cada
      entidad, por cien.
    </ContenedorDefinicion >
  );
}
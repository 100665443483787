const colores = [
    {
      from: 0,
      to: 0.0,
      color: "#FFFFFF",
      name: "0%"
    },
    {
      from: 0.01,
      to: 20,
      color: "#ffe6f7",
      name: "0.01% - 20%"
    },
    {
      from: 20.01,
      to: 40,
      color: "#efb9e0",
      name: "20.01% - 40%"
    },
    {
      from: 40.01,
      to: 60,
      color: "#da91e2",
      name: "40.01% - 60%"
    },
    {
      from: 60.01,
      to: 80,
      color: "#9876ce",
      name: "60.01% - 80%"
    },
    {
      from: 80.01,
      to: 99.99,
      color: "#694da0",
      name: "80.01% - 99.99%"
    },
    {
      from: 100,
      to: 100,
      color: "#4d4b75",
      name: "100%"
    }
  ];
  
  export default colores;
  
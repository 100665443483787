import React from "react";
//Constants
import { EtiquetasDatosInformativos } from "../../Constants/ConstantesGlobales";
//Assets

const DatosInformtivos = props => {
  const data = props.data;
  const mostrarCorte = false;
  const nombrePantalla = props.data.nombrePantalla;
  document.title = nombrePantalla;
  var path = props.path;
  var arreglo = path.split("/");
  var nivel = arreglo[2];

  const elementosMenu = document.getElementsByClassName("nav-link");

  switch (nivel) {
    case "1":
      elementosMenu[0].className += " active";
      elementosMenu[1].classList.replace("active", "notactive");
      elementosMenu[2].classList.replace("active", "notactive");
      // elementosMenu[3].classList.replace("active","notactive");
      break;
    case "2":
      elementosMenu[1].className += " active";
      elementosMenu[0].classList.replace("active", "notactive");
      elementosMenu[2].classList.replace("active", "notactive");
      // elementosMenu[3].classList.replace("active","notactive");
      break;
    case "3":
      elementosMenu[2].className += " active";
      elementosMenu[0].classList.replace("active", "notactive");
      elementosMenu[1].classList.replace("active", "notactive");
      // elementosMenu[3].classList.replace("active","notactive");
      break;
    case "4":
      // elementosMenu[3].className += " active";
      elementosMenu[0].classList.replace("active", "notactive");
      elementosMenu[1].classList.replace("active", "notactive");
      elementosMenu[2].classList.replace("active", "notactive");
      break;
    default:
  }
  return (
    <div className="lineaMedia datosInfCont">

      <div className="datosInf">
        <p><i className="far fa-clock iconoReloj ocultarMovil"></i>{EtiquetasDatosInformativos.etiqueta_ultimo_corte}{" "}{data.fechaHoraCorte}
        </p>
        <p>
          (<a href="http://www.cenam.mx/hora_oficial/" target="_blank" rel="noopener noreferrer">UTC-5</a>)
            <span> {EtiquetasDatosInformativos.etiqueta_tiempo_centro}</span>
        </p>
        {mostrarCorte && <p id="intervaloCorte">
          {EtiquetasDatosInformativos.etiqueta_intervalo_corte}<span>{data.intervaloJson}{EtiquetasDatosInformativos.etiqueta_intervalo_corte_minutos}</span>
        </p>
        }
      </div>
    </div>
  );
};

export default DatosInformtivos;

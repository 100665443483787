import React from "react";
//Plugins
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ProgressBar } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

//Components
import Botones from '../Genericos/Botones';
import {
  EtiquetasGenerales,
  EtiquetasBotones,
  EtiquetasIntalacionCasillas
} from "../../Constants/ConstantesGlobales";
import { obtenerAbreviaturaEntidad } from "../../Components/Auxiliares/TextUtils";
//import CAE from "./../../Assets/images/ico_CAE.svg";
//Auxiliares
import { formatNumber, formatPorcentaje, formatPorcentajeDoubleEs } from "../Auxiliares/ConvertNumber";

const TotalCasillasAprobadas = props => {


  var arreglo = props.path.split("/");
  var nivel = arreglo[arreglo.length - 1];
  const bandera = nivel == 2 ? false : true ;
  const idEntidad = props.data.id;
  

  const info = props.data.totales;
  const infoMayorAvance = props.data.mayorAvance;
  const infoMenorAvance = props.data.menorAvance;

  //Bandera que controla la componente de Mayor y Menor Avance
  const bandera2 = infoMayorAvance.total != null && infoMenorAvance.total != null ? true : false;


  const renderMenorAvance = (infoMenorAvance.porcentaje == 0  && infoMayorAvance.porcentaje  == 0 )  ? true : false;
  const renderMayorAvance = (infoMenorAvance.porcentaje == 100 && infoMayorAvance.porcentaje == 100)  ? true : false;

  const menorMayorIgual = infoMenorAvance.porcentaje == infoMayorAvance.porcentaje  ? true : false;


  const reducirNombre = (texto) => {
    switch (texto) {
      case "AGUASCALIENTES":
        return "AGS";
      case "BAJA CALIFORNIA":
        return "BC";
      case "COAHUILA":
        return "COAH";
      case "DURANGO":
        return "DGO";
      case "HIDALGO":
        return "HGO";
      case "PUEBLA":
        return "PUE";
      case "QUINTANA ROO":
        return "QROO";        
      case "TAMAULIPAS":
        return "TAM";        
      default:
    }
  }


  var faltantesUno;

  if ((100 - info.porcentajeCasillasInstaladas) === 0){
    faltantesUno = null;
  }else{
    faltantesUno = 100 - info.porcentajeCasillasInstaladas;
  }


  var faltantesDos;

  if ((100 - info.porcentajeCasillasPrimRepConc) === 0){
    faltantesDos = null;
  }else{
    faltantesDos = 100 - info.porcentajeCasillasPrimRepConc;
  }

  const optionsGraficaUno = {
    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      renderTo: "container",
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: 'transparent'
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#bf6da0", "#ececec"],
        dataLabels: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
  },

    series: [
      {
        name: "Total",
        data: [
          { name: "Instaladas", y: info.porcentajeCasillasInstaladas },
          { name: "Faltantes", y: faltantesUno  }
        ]
      }
    ]
  };

  const optionsGraficaDos = {
    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      renderTo: "container",
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: 'transparent'
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#bf6da0", "#ececec"],
        dataLabels: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
  },

    series: [
      {
        name: "Total",
        data: [
          { name: "Con primer reporte concluido", y: info.porcentajeCasillasPrimRepConc },
          { name: "Faltantes", y: faltantesDos  }
        ]
      }
    ]
  };

  return (
    <div>
      <div className="contenido borde">
        <p className="preludio2">
          {EtiquetasGenerales.etiqueta_general_total}: <br />
          <span className="enfasis2">
            {formatNumber(info.totalCasillasAprobadas)}
          </span>{" "}
          {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_aprobadas} <br />
          <span className="enfasis2">
            {formatNumber(info.totalCasillasReportadas)}
          </span>{" "}
          {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_reportadas}
        </p>

        <Row>
          <Col>
            <Row>
              <div className="graficaPie mb-2">
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { height: "100%" } }}
                  options={optionsGraficaUno}
                />
              </div>
              <div>
                <p className="enfasis1">
                  {formatPorcentajeDoubleEs(info.porcentajeCasillasInstaladas)}%
                </p>
                <p>
                  <strong>{formatNumber(info.totalCasillasInstaladas)}</strong>{" "}
                  {
                    EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_reportadas_instaladas
                  }
                </p>
              </div>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            {/*<div className="mb-4"></div>*/}
            <Row>
              <div className="graficaPie">
                <i className="fa fa-exclamation-triangle bigIcon mt-2"></i>
              </div>
              <div>
                <p className="enfasis1">
                  {formatPorcentajeDoubleEs(info.porcentajeCasillasNoInstaladas)}%
                </p>
                <strong>{formatNumber(info.totalCasillasNoInstaladas)}</strong>{" "}
                <span>
                  {
                    EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_reportadas_no_instaladas
                  }
                </span>
              </div>
            </Row>
          </Col>
        </Row>

        {/*<Row>
          <Col>
            <Botones
              tipoBoton="5"
              etiqueta={EtiquetasBotones.etiqueta_boton_incidentes}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>
        </Row>*/}

        <hr></hr>        

        <p className="preludio2">
          De las <span className="enfasis2">{formatNumber(info.totalCasillasInstaladas)}</span>{" "}
          casillas instaladas
        </p>

        <Row>
          <Col>
            <Row>
              <div className="graficaPie mb-2">
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { height: "100%" } }}
                  options={optionsGraficaDos}
                />
              </div>
              <div>
              <p className="enfasis1">
                    {formatPorcentajeDoubleEs(info.porcentajeCasillasPrimRepConc)}%
                  </p>
                  <p>
                    <strong>{formatNumber(info.totalCasillasPrimRepConc)}</strong>{" "}
                    {
                      EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_con_primer_reporte_concluido
                    }
                </p>
              </div>
            </Row>
             {/* <span className="enfasis2">
                {formatPorcentaje(info.porcentajeCasillasPrimRepConc)}%
              </span>
              <span>
                <strong> {formatNumber(info.totalCasillasPrimRepConc)}</strong>{" "}
                {
                  EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_con_primer_reporte_concluido
                }
              </span>
              */}
            
            {/*<div className="mb-2"></div>*/}
           {/* <ProgressBar now={info.porcentajeCasillasPrimRepConc} title={'Casillas con primer reporte concluido'} /> */}
          </Col>
        </Row>

      </div>

      <div>
        {
          (bandera && bandera2) ?
        (
        <Row className="lineaVertical">
          <Col xs={6} xl={6} sm={6} md={6} lg={6}>
            <div className={"entidadMasMenos " + obtenerAbreviaturaEntidad(infoMayorAvance.nombre)}></div>
            <div>
              <p className="masMenosSub">
                {EtiquetasIntalacionCasillas.etiqueta_instalacion_mayor_avance}
              </p>
              <p className="enfasis2">{infoMayorAvance.nombre}</p>
              <p className="enfasis2">{formatPorcentajeDoubleEs(infoMayorAvance.porcentaje)}%</p>
              <p>
                <strong>{formatNumber(infoMayorAvance.total)}</strong>
                {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_instaladas}
              </p>
            </div>
          </Col>
          <Col xs={6} xl={6} sm={6} md={6} lg={6}>
            <div className={"entidadMasMenos " + obtenerAbreviaturaEntidad(infoMenorAvance.nombre)}></div>
            <div>
              <p className="masMenosSub">
                {EtiquetasIntalacionCasillas.etiqueta_instalacion_menor_avance}
              </p>
              <p className="enfasis2">{infoMenorAvance.nombre}</p>
              <p className="enfasis2">{formatPorcentajeDoubleEs(infoMenorAvance.porcentaje)}%</p>
              <p>
                <strong>{formatNumber(infoMenorAvance.total)}</strong>
                {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_instaladas}
              </p>
            </div>
          </Col>
        </Row>

        ) : (
          bandera2 ? 
          (

                <div>

                  {renderMayorAvance ? (
                    
                    <Row >
                        <Col xs={12} xl={12} sm={12} md={12} lg={12} className="text-center">
                        <div>
                            <p className="masMenosSub">
                              {EtiquetasIntalacionCasillas.etiqueta_instalacion_mayor_avance}
                            </p>
                            <p className="small mb-2">{infoMayorAvance.nombre}</p>
                            <p className="enfasis2">Distrito {infoMayorAvance.id}</p>
                            <p className="enfasis2">{formatPorcentajeDoubleEs(infoMayorAvance.porcentaje)}%</p>
                            <p>
                              <strong className="mr-1">{formatNumber(infoMayorAvance.total)}</strong>
                              {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_instaladas}
                            </p>
                          </div>
                       
                        </Col>
                    </Row>
                  ) : (<div>

                      {renderMenorAvance ? (
                          <Row >
                              <Col xs={12} xl={12} sm={12} md={12} lg={12} className="text-center">
                                    <div>
                                        <p className="masMenosSub">
                                          {EtiquetasIntalacionCasillas.etiqueta_instalacion_menor_avance}
                                        </p>
                                        <p className="small mb-2">{infoMenorAvance.nombre}</p>
                                        <p className="enfasis2">Distrito {infoMenorAvance.id}</p>
                                        <p className="enfasis2">{formatPorcentajeDoubleEs(infoMenorAvance.porcentaje)}%</p>
                                        <p>
                                          <strong className="mr-1">{formatNumber(infoMenorAvance.total)}</strong>
                                          {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_instaladas}
                                        </p>
                                    </div>
                              
                              </Col>
                          </Row>
                      ) : (  
                        <div>

                          {!renderMenorAvance && !renderMayorAvance ? (

                            <div>
                              
                              {menorMayorIgual ? ( 
                            
                              <Row >
                                  <Col xs={12} xl={12} sm={12} md={12} lg={12} className="text-center">
                                      <div>
                                          <p className="masMenosSub">
                                              {EtiquetasIntalacionCasillas.etiqueta_instalacion_mayor_avance}
                                            </p>
                                            <p className="small mb-2">{infoMayorAvance.nombre}</p>
                                            <p className="enfasis2">Distrito {infoMayorAvance.id}</p>
                                            <p className="enfasis2">{formatPorcentajeDoubleEs(infoMayorAvance.porcentaje)}%</p>
                                            <p>
                                              <strong className="mr-1">{formatNumber(infoMayorAvance.total)}</strong>
                                              {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_instaladas}
                                            </p>
                                        </div>
                              
                                  </Col>
                              </Row>
                            
                          ) : ( <div>

                                    <Row className="lineaVertical">
                                      <Col xs={6} xl={6} sm={6} md={6} lg={6} className="text-center">
                                          <div>
                                            <p className="masMenosSub">
                                              {EtiquetasIntalacionCasillas.etiqueta_instalacion_mayor_avance}
                                            </p>
                                            <p className="small mb-2">{infoMayorAvance.nombre}</p>
                                            <p className="enfasis2">Distrito {infoMayorAvance.id}</p>
                                            <p className="enfasis2">{formatPorcentajeDoubleEs(infoMayorAvance.porcentaje)}%</p>
                                            <p>
                                              <strong className="mr-1">{formatNumber(infoMayorAvance.total)}</strong>
                                              {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_instaladas}
                                            </p>
                                          </div>
                                      </Col>
                                      <Col xs={6} xl={6} sm={6} md={6} lg={6} className="text-center">
                                        <div>
                                          <p className="masMenosSub">
                                            {EtiquetasIntalacionCasillas.etiqueta_instalacion_menor_avance}
                                          </p>
                                          <p className="small mb-2">{infoMenorAvance.nombre}</p>
                                          <p className="enfasis2">Distrito {infoMenorAvance.id}</p>
                                          <p className="enfasis2">{formatPorcentajeDoubleEs(infoMenorAvance.porcentaje)}%</p>
                                          <p>
                                            <strong className="mr-1">{formatNumber(infoMenorAvance.total)}</strong>
                                            {EtiquetasIntalacionCasillas.etiqueta_instalacion_casillas_instaladas}
                                          </p>
                                        </div>
                                      </Col>
                                  </Row>

                          </div> )}
                          
                          </div>
                            
                            

                              

                          ) : ( <div></div> )}


                    </div> ) }

                  </div> ) }


                  </div>       
                


          ) : (
            <br />
            )    
      )}


        <Row className="ocultarMovil mb-3">
          <Col>
            <Botones
              tipoBoton="2"
              etiqueta={EtiquetasBotones.etiqueta_boton_base_datos}
              path={props.path}
              idEntidad={idEntidad}
            ></Botones>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TotalCasillasAprobadas;

import React from 'react';
import Rutas from './Router/Rutas';
import './Assets/css/Styles.css';
import ReactGA from 'react-ga';

function App() {
  ReactGA.initialize('UA-176716852-26');
  ReactGA.pageview(window.location.pathname + window.location.search);
  console.log('ReactGA: ', ReactGA);
  return (
    <Rutas></Rutas>
  );
}

export default App;

import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mapa from "../../Assets/json/Mapas/Mapas.js";
import colores from "../../Assets/json/Mapas/Colores.js";
import coloresIncidentes from "../../Assets/json/Mapas/ColoresIncidentes.js";

//Plugins
import { Row, Col } from "react-bootstrap";
//Constants
import { EtiquetasBotones } from "../../Constants/ConstantesGlobales";
import Botones from "../Genericos/Botones";
// Load Highcharts modules
require("highcharts/modules/map")(Highcharts);

const MapaNacional = props => {

  const rutaMapa = props.path;
  var origen = rutaMapa.includes("Incidentes");

  var pintarIncidentes = false;
  var coloresAux = "";
  var pointFormatText = "";
  var infoIncidentesTotales = props.data.totales;

  if (origen) {
    pintarIncidentes = true;
    coloresAux = coloresIncidentes;
    pointFormatText = "<p>{point.value} Incidentes</p>";
  } else {
    pintarIncidentes = false;
    coloresAux = colores;
    pointFormatText = "<p>{point.value:.2f} %</p>";

  }

  /* Se crea un arreglo que contiene la información de los estados */
  var aux = props.data.mapa;
  let arreglos = [];
  for (var i = 0; i <= aux.length - 1; i++) {
    arreglos.push([aux[i].nombre, aux[i].value]);
  }
  var seriesInfo = arreglos;

  let arreglosColores = [];
  for (var j = 0; j < coloresAux.length; j++) {
    arreglosColores.push(coloresAux[j].color);
  }

  /* Opciones para armar el mapa */

  const mapOptions = {
    title: {
      text: ""
    },

    chart: {
      backgroundColor: "none"
    },

    credits: {
      enabled: false
    },

    exporting: {
      enabled: false
    },

    legend: {
      align: "right",
      verticalAlign: "top",
      x: 0,
      y: 0,
      itemMarginBottom: 5,
      floating: true,
      layout: "vertical",
      valueDecimals: 0,
      enabled: true
    },

    mapNavigation: {
      enabled: false,
      enableButtons: false
    },
    tooltip: {
      style: {
        color: "white"
      },

      backgroundColor: "#363642",
      headerFormat: "<small><strong>{point.nombre}</strong></small><br/>",
      pointFormat: "<strong>{point.id} - {point.nombre}</strong><br/>" + pointFormatText
    },

    plotOptions: {
      map: {
        mapData: mapa,
        joinBy: ["ID", "id"],
        cursor: "pointer"
      }
    },

    colors: {
      dataClasses: [arreglosColores]
    },

    colorAxis: {
      dataClasses: coloresAux
    },

    series: [
      {
        data: [...props.data.mapa],
        borderColor: "#b2b2b2",
        borderWidth: 1,
        point: {
          events: {
            click: e => {
              Rutas(e, rutaMapa);
            }
          }
        }
      }
    ]
  };
  
  return (
    <div>
      <div className="contenido borde">
        <HighchartsReact
          options={mapOptions}
          constructorType={"mapChart"}
          highcharts={Highcharts}
        />
      </div>

      <Row className="mb-3">
        <Col xs={7} xl={7} sm={8} md={7} lg={7}>

          {pintarIncidentes ? (
            <p>
              <strong>
                <span className="enfasis1">
                  {infoIncidentesTotales.totalIncidentes}
                </span>
                {" "}incidentes reportados en{" "}
                <span className="enfasis2">
                  {infoIncidentesTotales.totalEntidades}
                </span>
                {" "}Entidades
          </strong>
            </p>
          ) : (
            <p></p>
          )}

        </Col>

        <Col xs={5} xl={5} sm={4} md={5} lg={5}>
          <Botones
            tipoBoton="6"
            etiqueta={EtiquetasBotones.etiqueta_boton_detalle_entidad}
            path={props.path}
          ></Botones>
        </Col>
      </Row>
    </div>
  );
};

function Rutas(e, rutaMapa) {
  var idEstado = e.point.properties.ID;
  var aux = rutaMapa.split("/");
  var Nivel = "";
  switch (Number(aux[2])) {
    case 1:
      Nivel = "ResumenGeneralEntidad";
      break;
    case 2:
      Nivel = "InstalacionCasillasEntidad";
      break;
    case 3:
      Nivel = "SegundoReporteEntidad";
      break;
    case 4:
      Nivel = "IncidentesEntidad";
      break;
    default:
  }
  var ruta = "/" + Nivel + "/" + aux[2] + "/2?idEntidad=" + idEstado;
  return (document.location.href = ruta);
}

export default MapaNacional;

import React from "react";
import { Modal } from "react-bootstrap";

const ModalIncidentes = props => {
  var info = props.data;
  return (
    <Modal {...props} className="modalIncidentes">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>Etapa de la Jornada Electoral:</small>
          <p className="enfasis1">{info.etapa}</p>
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Incidente: <span className="enfasis2">{info.inciso}</span></p>
        <p>{info.descripcion}</p>
      </Modal.Body>
    </Modal>
  );
};

export default ModalIncidentes;

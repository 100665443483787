import React, { Component } from "react";
import DatosInformativos from "../../Components/Genericos/DatosInformativos";
import Botones from "../../Components/Genericos/Botones";
//Plugins
import { Container, Row, Col, Form } from "react-bootstrap";
import queryString from "query-string";
//Constants
import { EtiquetasBotones, EtSegRep } from "../../Constants/ConstantesGlobales";
//JsonMenu
import jsonMenu from "../../Assets/json/Menu/Menu.json";
import Encabezado from "../../Components/Genericos/Encabezado";
import SubEncabezado from "../../Components/Genericos/SubEncabezado";

import TipoAplicativo from "../../Components/InstalacionCasillas/TipoAplicativo";
import ModalEmergente from "../../Components/Genericos/ModalEmergente";
import TotalCasillasAprobadasSegundoReporteResponsivo from "../../Components/SegundoReporte/TotalCasillasAprobadasSegundoReporteResponsivo";
import MesasDirectivas from "../../Components/SegundoReporte/MesasDirectivas";
import RepresentatesPPCI from "../../Components/SegundoReporte/RepresentantesPPCI";
import ObservadoresElectorales from "../../Components/SegundoReporte/ObservadoresElectorales";
import Cargando from "../../Components/Genericos/Cargando";
import GraficoLinea from "../../Components/Graficos/GraficoLinea";
import { DefCasillasSegundoReporte } from "../../Components/Auxiliares/EtiquetasComponentes"
import RepresentantesPPCIInformacion from "../../Components/SegundoReporte/RepresentantesPPCIInformacion";

//////////// CORS ////////////

var rutaCatalogos = "/json/Catalogos/CatalogoEstados.json";
var rutaWeb = "/SegundoReporteDistritosFederales/3/3?";
var rutaArchivos = "/json/Secciones/SegundoReporte/DistritosFederales/SegundoReporteDistritosFederales_";
var defaultEntidad = 1;
var defaultDistritoFederal = 1;
var myHeaders = new Headers();

myHeaders.append("X-Content-Type-Options", "nosniff");
myHeaders.append("X-Frame-Options", "deny");
myHeaders.append("X-XSS-Protection", "1;mode=block");
myHeaders.append("Cache-Control", "no-cache, no-store, must-revalidate");

myHeaders.append("Content-Type", "text/plain");
myHeaders.append(
  "Access-Control-Allow-Headers",
  "Origin, X-Requested-With, Content-Type, Accept"
);

var headers = {
  method: "GET",
  headers: myHeaders
};

var serieObj = {
  name: "Casillas con Segundo reporte",
  marker: {
    fillColor: '#BF6DA0',
    radius: 7,
    states: {
      hover: {
        fillColor: '#D4007F',
        lineWidthPlus: 0,
        radiusPlus: 4,
      }
    }
  },
  color: '#363642',
}
//////////////////////////////

class SegundoReporteDistritosFederales extends Component {
  constructor() {
    super();

    this.state = {
      data: null,
      estados: null,
      origenDatos: null,
      descripcionSeccion: null,
      descripcionNivel: null,
      path: null,
      showModal: false,
      idEntidad: null,
      idDistritoFederal: -1,
      distritosFederales: [],
      graficaConf: {},
      disabledSelectDistritosFederales: true,
      titulo: 'Reportes por tipo de aplicativo'
    };
  }

  componentDidMount() {
    var path = this.props.location.pathname;
    var arreglo = path.split("/");

    var nivel = arreglo[arreglo.length - 1];
    var seccion = arreglo[arreglo.length - 2];

    var palabra = jsonMenu.secciones.filter(
      secciones => secciones.idSeccion === Number(seccion)
    );

    var aux = palabra[0].nivelNavegacion;

    var descripcionSeccion = palabra[0].descripcionSeccion;
    var descripcionNivel = aux[nivel - 1].descripcionNivel;
    var origenDatos = aux[nivel - 1].origenDatos;
    let queryJSON = queryString.parse(this.props.location.search);
    let idEntidad = queryJSON.idEntidad !== undefined ? parseInt(queryJSON.idEntidad) : defaultEntidad;
    let idDistritoFederal = queryJSON.idDistritoFederal !== undefined ? parseInt(queryJSON.idDistritoFederal) : defaultDistritoFederal;

    this.setState({
      descripcionSeccion: descripcionSeccion,
      descripcionNivel: descripcionNivel,
      origenDatos: origenDatos,
      path: path,
      disabledSelectDistritosFederales: idEntidad !== -1 ? false : true,
      idEntidad: idEntidad,
      idDistritoFederal: idDistritoFederal
    });


    fetch(rutaCatalogos, headers)
      .then(resolve => {
        return resolve.json();
      })
      .then(info => {
        this.setState({
          estados: info,
          distritosFederales: this.obtenerDistritosFederales(info.estados, idEntidad)
        });

        this.actualizarJson(idEntidad, idDistritoFederal);
      })
      .catch(error => {
        console.log("Catalogo. El error es : ", error);
      });
  }

  componentDidUpdate(prevProps) {
    // Sólo para cargar la información cuando hace el corte.
  }

  actualizarJson(idEntidad, idDistritoFederal) {
    this.props.history.push(`${rutaWeb}idEntidad=${idEntidad}&idDistritoFederal=${idDistritoFederal}`);
    let ruta = `${rutaArchivos}${idEntidad}_${idDistritoFederal}.json`;

    fetch(ruta, headers)
      .then(resolve => {
        return resolve.json();
      })
      .then(info => {
        let newInfo = {
          ...info,
          direccionTotalCasillasPreludio: 1,
          noMostrarBtn: true
        }
        this.setState({
          data: newInfo,
          graficaConf: {
            xAxis: {
              categories: newInfo.graficoLinea && newInfo.graficoLinea[0].x ? [...newInfo.graficoLinea[0].x] : ['7:00', '7:30', '8:00'],
            },
            series: [{
              ...serieObj,
              data: newInfo.graficoLinea && newInfo.graficoLinea[1].y ? [...newInfo.graficoLinea[1].y] : [1, 2, 3]
            }]
          }
        });
      }).catch(error => {
        console.log("El error es : ", error);
      });
  }

  handleChange = event => {
    let idDistritoFederal = parseInt(event.target.value);

    this.setState({
      idDistritoFederal: idDistritoFederal
    });

    this.actualizarJson(this.state.idEntidad, idDistritoFederal);
  };

  obtenerDistritosFederales = (estados, idEntidad) => {
    let entidadObj = estados.find(estado => estado["idEstado"] === idEntidad);

    return entidadObj ? entidadObj.distritosFederales : [];
  }

  handleChangeEntidadSelect = event => {
    let disabled = true;
    let idEntidad = parseInt(event.target.value);

    if (idEntidad !== -1) {
      disabled = false;
    }


    this.setState({
      distritosFederales: this.obtenerDistritosFederales(this.state.estados.estados, idEntidad),
      idDistritoFederal: defaultDistritoFederal,
      disabledSelectDistritosFederales: disabled,
      idEntidad: idEntidad
    });

    this.actualizarJson(idEntidad, defaultDistritoFederal);
  };

  render() {
    if (!this.state.data || !this.state.origenDatos) {
      return (
        <div>
          <Cargando />
        </div>
      );
    }

    if (
      !this.state.descripcionNivel ||
      !this.state.descripcionSeccion ||
      !this.state.estados ||
      !this.state.idEntidad
    ) {
      return (
        <div>
          <Cargando />
        </div>
      );
    }

    return (
      <div>
        {/* Datos Informativos */}
        <Container>
          <Row>
            <Col xs={8} xl={12} sm={8} md={12} lg={12}>
              <DatosInformativos
                data={this.state.data}
                path={this.state.path}
                nivel={this.state.descripcionSeccion}
              />
            </Col>
            <Col xs={4} sm={4} className="ocultarDesktop">
              <Botones
                tipoBoton="1"
                etiqueta={EtiquetasBotones.etiqueta_boton_actualizar}
                path={this.state.path}
              />
            </Col>
          </Row>
          {/* Titulo de la página y botones de actualizacion y descarga */}
          <Row>
            <Col xs={12} xl={8} sm={12} md={8} lg={8}>
              <Encabezado
                descripcionSeccion={this.state.descripcionSeccion}
                descripcionNivel={this.state.descripcionNivel}
              />
            </Col>
            <Col xs={12} xl={4} sm={12} md={4} lg={4} className="ocultarMovil">
              <Botones
                tipoBoton="1"
                etiqueta={EtiquetasBotones.etiqueta_boton_actualizar}
                path={this.state.path}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} xl={4} sm={12} md={12} lg={4}>
              <Form>
                <Form.Group controlId="segundoReporte.EntidadSelect">
                  <Form.Control
                    as="select"
                    defaultValue={this.state.idEntidad}
                    onChange={this.handleChangeEntidadSelect.bind(this)}
                  >
                    {this.state.estados.estados.map((info) => (
                      <option key={info.idEstado} value={info.idEstado}>
                        {info.nombreEstado}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Col>

            <Col xs={12} xl={4} sm={12} md={12} lg={4}>
              <Form>
                <Form.Group controlId="segundoReporte.DistritosFederalesSelect">
                  <Form.Control
                    as="select"
                    value={this.state.idDistritoFederal}
                    onChange={this.handleChange.bind(this)} disabled={this.state.disabledSelectDistritosFederales}
                  >
                    {this.state.distritosFederales.map((info) => (
                      <option key={info.idDistritoFederal} value={info.idDistritoFederal}>
                        {info.idDistritoFederal}. {info.nombreDistritoFederal}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Col>
          </Row>

          <Row className="mb-4 ocultarMovil">
            <Col xs={12} xl={12} sm={12} md={12} lg={12}>
              <SubEncabezado mensaje={EtSegRep.segundo_reporte_distritos_federales} />
            </Col>
            <Col xs={3} xl={3} sm={3} md={3} lg={3}></Col>
          </Row>

          <Row className="mb-3">
            <Col xs={10} xl={10} sm={10} md={10} lg={10}>
              <h2>
                {EtSegRep.casillas_con_segundo_reporte_titulo_entidad}
                {<span
                  onClick={() => this.openModal()}
                  className="quitarSubrayado">
                  <i className="fas fa-question-circle info"></i>
                </span>}
              </h2>
            </Col>
            <Col xs={2} xl={2} sm={2} md={2} lg={2} className="ocultarMovil">
              <Botones
                tipoBoton="2"
                etiqueta={EtiquetasBotones.etiqueta_boton_base_datos}
                path={this.state.path}
              />
            </Col>
          </Row>

          <Row className="mb-5">
            <Col xs={12} xl={12} sm={12} md={12} lg={12}>
              <GraficoLinea conf={{ ...this.state.graficaConf }} />
            </Col>
          </Row>

          <Row>
            <Col xs={12} xl={12} sm={12} md={12} lg={12}>
              <TotalCasillasAprobadasSegundoReporteResponsivo
                data={this.state.data}
                path={this.state.path}
              />
            </Col>
          </Row>

          <MesasDirectivas data={this.state.data.mesasDirectivas} />


          {this.state.data.representantesInformacion &&
            Array.isArray(this.state.data.representantesInformacion) &&
            this.state.data.representantesInformacion.length > 0 &&
            <Row>
              <RepresentantesPPCIInformacion data={this.state.data} />
            </Row>
          }


          <Row>
            <Col xs={12} xl={8} sm={12} md={12} lg={8}>
              <RepresentatesPPCI data={this.state.data} />
            </Col>
            <Col xs={12} xl={4} sm={12} md={12} lg={4}>
              <ObservadoresElectorales
                data={this.state.data}
              />
            </Col>
          </Row>

          <TipoAplicativo data={this.state.data} title={this.state.titulo} />
        </Container>

        {/* Modal */}
        <ModalEmergente
          show={this.state.showModal}
          onHide={() => this.close()}
        >
          <div>Acerca del cálculo</div>
          <DefCasillasSegundoReporte className="mb-3" />
        </ModalEmergente>
      </div>
    );
  }

  openModal = _ => {
    this.setState({ showModal: true });
  };

  close = _ => {
    this.setState({ showModal: false });
  };
}

export default SegundoReporteDistritosFederales;

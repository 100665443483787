import React from "react";
import { Link } from "react-router-dom";


const Botones = props => {
  var path = props.path;

  var arreglo = path.split("/");
  var nivel = arreglo[arreglo.length - 1];

  switch (props.tipoBoton) {
    case "1":
      return botonActualizar(props, nivel);
    case "2":
      return botonDescarga(props, nivel);
    case "3":
      return botonInstalacionCasillas(props, nivel);
    case "4":
      return botonSegundoReporte(props, nivel);
    case "5":
      return botonIncidentes(props, nivel);
    case "6":
      return botonCambioEntidad(props);
    default:
      return null;
  }
};

const botonCambioEntidad = (props) => {
  var arreglo = props.path.split("/");
  var seccion = arreglo[2];
  var ruta = "";

  switch (seccion) {
    case "2":
      ruta = "/InstalacionCasillasEntidad/2/2";
      break;
    case "3":
      ruta = "/SegundoReporteEntidad/3/2";
      break;
    case "4":
      ruta = "/IncidentesEntidad/4/2";
      break;
    default:
  }

  return (
    <button type="button" className="botonTransparente">
      <Link to={ruta}>{props.etiqueta}</Link>
    </button>
  );
};

const botonInstalacionCasillas = (props, nivel) => {
  var ruta =
    nivel === "1"
      ? "/InstalacionCasillasNacional/2/1"
      : "/InstalacionCasillasEntidad/2/2?idEntidad=" + props.idEntidad;

  return (
    <button type="button" className="botonTransparenteResumenGral">
      <Link to={ruta}>{props.etiqueta}</Link>
    </button>
  );
};

const botonSegundoReporte = (props, nivel) => {
  var ruta =
    nivel === "1"
      ? "/SegundoReporteNacional/3/1"
      : "/SegundoReporteEntidad/3/2?idEntidad=" + props.idEntidad;

  return (
    <button type="button" className="botonTransparenteResumenGral">
      <Link to={ruta}>{props.etiqueta}</Link>
    </button>
  );
};

const botonIncidentes = (props, nivel) => {
  var ruta =
    nivel === "1"
      ? "/IncidentesNacional/4/1"
      : "/IncidentesEntidad/4/2?idEntidad=" + props.idEntidad;
  return (
    <button type="button" className="botonTransparente">
      <Link to={ruta}>{props.etiqueta}</Link>
    </button>
  );
};

const botonActualizar = (props, nivel) => {
  return (
    <button type="button" className="botonTransparente" onClick={reload}>
      {props.etiqueta}
    </button>
  );
};

const botonDescarga = (props, nivel) => {
  return (
    <button type="button" className="botonMorado" onClick={descargaBase}>
      <i className="fa fa-download download "></i>
      <a className="linkBlanco texto16pxMedium textoColorBlanco">
        {props.etiqueta}
      </a>
    </button>
  );
};

//Recargar página
const reload = () => {
  window.location.reload(true);
};

function get(url) {
  return new Promise((accept, reject) => {
    var req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "arraybuffer";
    req.onload = function(event) {
      var resp = req.response;
      if (resp) {
        accept(resp);
      }
    };
    req.send(null);
  });
}

async function descargaBase() {
let promesa = new Promise (function (resolve, reject){
  resolve("SIJE.zip");
  // fetch("/json/Secciones/Configuracion.json")
  // .then(resolve => {
  //   return resolve.json();
  // })
  // .then(info => {
  //   resolve(info.rutaArchivo);    
  // })
  // .catch(error => {
  //   console.log("El error es : ", error);
  // });
})


promesa.then(function(rutaArchivoJson){
  let ruta = "/bd/"+rutaArchivoJson;
  console.log ("ruta", ruta);
  get(ruta).then(function(resolve) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    const blob = new Blob([resolve], {
      type: "application/zip"
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    //a.download = "SIJE2019.zip";
    a.download = rutaArchivoJson;
    fetch(URL.createObjectURL(blob)).then(res => res.arrayBuffer());
    if (a.click) {
      a.click();
    } else if (document.createEvent) {
      var eventObj = document.createEvent("MouseEvents");
      eventObj.initEvent("click", true, true);
      a.dispatchEvent(eventObj);
    }
    window.URL.revokeObjectURL(url);
  });
}) 


  
}

export default Botones;

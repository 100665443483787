import React from "react";
//Plugins
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col, ProgressBar } from "react-bootstrap";

//Components
import { formatNumber, formatPorcentaje } from "../Auxiliares/ConvertNumber";
import {
  EtiquetasBotones,
  EtIncidentes
} from "../../Constants/ConstantesGlobales";
import Botones from "../Genericos/Botones";

const TotalCasillasAprobadasIncidentes = props => {
  
  var arreglo = props.path.split("/");
  var nivel = arreglo[arreglo.length - 1];
  const bandera = nivel == 2 ? false : true;

  const info = props.data.totales;
  const infoMayorAvance = props.data.mayorAvance;
  const infoMenorAvance = props.data.menorAvance;


  const reducirNombre = texto => {
    switch (texto) {
      case "AGUASCALIENTES":
        return "AGS";
      case "BAJA CALIFORNIA":
        return "BC";
      case "COAHUILA":
        return "COAH";
      case "DURANGO":
        return "DGO";
      case "HIDALGO":
        return "HGO";
      case "PUEBLA":
        return "PUE";
      case "QUINTANA ROO":
        return "QROO";
      case "TAMAULIPAS":
        return "TAM";
      default:
    }
  };

  var faltantesUno = "";
  
  if (100 - info.porcentajeCasillasIncidente === 0) {
    faltantesUno = null;
  } else {
    faltantesUno = 100 - info.porcentajeCasillasIncidente;
  }

  const optionsGraficaUno = {
    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      renderTo: "container",
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: "rgba(0,0,0,0)"
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },
      pie: {
        colors: ["#bf6da0", "#ececec"],
        dataLabels: {
          enabled: false
        }
      },
      states: {
        hover: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },

    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.3f}%</b>"
    },

    series: [
      {
        name: "Total",
        data: [
          { name: "Con incidentes", y: info.porcentajeCasillasIncidente },
          { name: "Sin incidentes", y: faltantesUno }
        ]
      }
    ]
  };

  return (
    <div>
      <div className="contenido borde mb-3">
        <p className="preludio2">
          Total: <br />
          <span className="enfasis2">
            {formatNumber(info.totalCasillasAprobadas)}
          </span>{" "}
          casillas aprobadas
        </p>

        <Row>
          <Col>
            <Row>
              <div className="graficaPie">
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { height: "100%" } }}
                  options={optionsGraficaUno}
                />
              </div>
              <div>
                <p className="enfasis1">
                  {formatPorcentaje(info.porcentajeCasillasIncidente)}%
                </p>
                <p>
                  <strong>{formatNumber(info.totalCasillasIncidente)}</strong>{" "}
                  casillas con Incidentes{" "}
                </p>
              </div>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="contenido borde">
        <Row>
          <Col>
            <div>
              <p className="preludio2">
                De los <span className="enfasis2">{formatNumber(info.totalIncidentes)}</span> incidentes
                reportados:
              </p>
            </div>
          </Col>
        </Row>

        <Row className="comparaIncidentes">
          <Col>
            <p className="mb-1"><span className="enfasis2">{info.porcentajeNoInstalada}%</span> Casilla no instalada</p>
            <ProgressBar>
              <ProgressBar
                striped
                
                now={info.porcentajeNoInstalada}
                key={1}
              />
              <ProgressBar
                
                now={info.porcentajeOtrosIncidentes}
                key={2}
              />
            </ProgressBar>
            <p className="text-right">Otros incidentes <span className="enfasis2">{info.porcentajeOtrosIncidentes}%</span></p>
          </Col>
        </Row>
      </div>

      <div>
        {bandera ? (
          <Row className="lineaVertical">
            <Col xs={6} xl={6} sm={6} md={6} lg={6}>
              <div className={"entidadMasMenos " + reducirNombre(infoMayorAvance.nombre)}></div>
              <div>
                <p className="masMenosSub">{EtIncidentes.incidentes_menos}</p>
                <p className="enfasis2">
                  {reducirNombre(infoMayorAvance.nombre)}
                </p>
                <p>
                  <span className="enfasis2">
                    {formatNumber(infoMayorAvance.totalIncidentes)}
                  </span> incidentes en <strong>{formatNumber(infoMayorAvance.totalCasillas)}</strong> casillas
                </p>
              </div>
            </Col>
            <Col xs={6} xl={6} sm={6} md={6} lg={6}>
              <div className={"entidadMasMenos " + reducirNombre(infoMenorAvance.nombre)}></div>
              <div>
                <p className="masMenosSub">{EtIncidentes.incidente_mas}</p>
                <p className="enfasis2">
                  {reducirNombre(infoMenorAvance.nombre)}
                </p>
                <p>
                  <span className="enfasis2">
                    {formatNumber(infoMenorAvance.totalIncidentes)}
                  </span> incidentes en <strong>{formatNumber(infoMenorAvance.totalCasillas)}</strong> casillas
                </p>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="lineaVertical">
            <Col xs={6} xl={6} sm={6} md={6} lg={6} className="text-center">
              <div>
                <p className="masMenosSub">{EtIncidentes.incidentes_menos}</p>
                <p className="small mb-2">{infoMayorAvance.nombre}</p>
                <p className="enfasis2">Distrito {infoMayorAvance.id}</p>
                <span className="enfasis2">
                  {infoMayorAvance.totalIncidentes}{" "}
                </span> incidentes en
                <p>
                  <strong className="mr-1">
                    {" "}
                    {formatNumber(infoMayorAvance.totalCasillas)}{" "}
                  </strong>
                  casillas
                </p>
              </div>
            </Col>
            <Col xs={6} xl={6} sm={6} md={6} lg={6} className="text-center">
              <div>
                <p className="masMenosSub">{EtIncidentes.incidente_mas}</p>
                <p className="small mb-2">{infoMenorAvance.nombre}</p>
                <p className="enfasis2">Distrito {infoMenorAvance.id}</p>
                <strong className="enfasis2">{infoMenorAvance.totalIncidentes}{" "}</strong>
                incidentes en
                <p>
                  <strong className="mr-1">
                    {formatNumber(infoMenorAvance.totalCasillas)}{" "}
                  </strong>
                  casillas
                </p>
              </div>
            </Col>
          </Row>
        )}

        <Row className="ocultarMovil mb-3">
          <Col>
            <Botones
              tipoBoton="2"
              etiqueta={EtiquetasBotones.etiqueta_boton_base_datos}
              path={props.path}
            ></Botones>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TotalCasillasAprobadasIncidentes;

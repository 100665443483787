import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import MapasEntidades from "../../Assets/json/MapasEstados/MapasEntidades";
import { AlertaGeneralElemento } from "../../Components/Genericos/Alertas";

import colores from "../../Assets/json/Mapas/Colores.js";
import coloresIncidentes from "../../Assets/json/Mapas/ColoresIncidentes.js";

// Load Highcharts modules
require("highcharts/modules/map")(Highcharts);
const MapaEntidad = props => {
  const rutaMapa = props.path;
  var origen = "";
  origen = rutaMapa.includes("Incidentes");
  var pintarIncidentes = false;
  var coloresAux = "";
  var pointFormatText = "";

  if (origen) {
    pintarIncidentes = true;
    coloresAux = coloresIncidentes;
    pointFormatText = "<p>{point.value} Incidentes</p>";
  } else {
    pintarIncidentes = false;
    coloresAux = colores;
    pointFormatText = "<p>{point.value:.2f} %</p>";
  }

  var infoIncidentesTotales = props.data.totales;
  var Mapa = MapasEntidades[`mapa${props.data.id}`];

  let mapOptions = {
    title: {
      text: ""
    },

    chart: {
      backgroundColor: "none"
    },

    credits: {
      enabled: false
    },

    exporting: {
      enabled: false
    },

    legend: {
      align: "right",
      backgroundColor: "rgba(255,255,255,0.9)",
      verticalAlign: "top",
      x: 0,
      y: 0,
      itemMarginBottom: 5,
      floating: true,
      layout: "vertical",
      valueDecimals: 0,
      enabled: true
    },

    mapNavigation: {
      enabled: false,
      enableButtons: false
    },

    tooltip: {
      style: {
        color: "white"
      },
      backgroundColor: "#363642",
      headerFormat:
        '<p style="fontWeight: "bold" "> <strong>{point.nombre}</strong></p><br/>',
      pointFormat:
        "<p><strong>{point.id} - {point.nombre}</strong><br/> </p>" + pointFormatText
    },

    plotOptions: {
      map: {
        mapData: Mapa,
        joinBy: ["ID", "id"],
        cursor: "pointer"
      }
    },

    colors: {
      dataClasses: [...coloresAux]
    },

    colorAxis: {
      dataClasses: coloresAux
    },

    series: [
      {
        data: [...props.data.mapa],
        borderColor: "#000000",
        borderWidth: 1
      }
    ]
  };

  return (
    <div>
      <div className="contenido borde">
        {Mapa && <HighchartsReact
          options={mapOptions}
          highcharts={Highcharts}
          constructorType={"mapChart"}
          allowChartUpdate={true}
          immutable={true}
          updateArgs={[true, true, false]}
        />}
        {!Mapa &&
          <div className="error-mapa">
            <AlertaGeneralElemento />
          </div>
        }
      </div>
      {pintarIncidentes ? (
        <p>
          <strong>
            <span className="enfasis1">
              {infoIncidentesTotales.totalIncidentes}
            </span>
            {" "}incidentes reportados en{" "}
            <span className="enfasis2">
              {infoIncidentesTotales.totalEntidades}
            </span>
            {" "}Distritos Federales
          </strong>
        </p>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default MapaEntidad;

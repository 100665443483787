import React from "react";

const SubEncabezado = ({ mensaje }) => {
  return (

      <p className="subEncabezado">{mensaje}</p>

  );
};

export default SubEncabezado;

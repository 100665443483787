import React, { useCallback, useState } from "react";

//Constantes
import { EtiquetasGenerales } from "../../Constants/ConstantesGlobales";
//Plugins, components
import { Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
//Auxiliares
import { formatNumber } from "../Auxiliares/ConvertNumber";

const IncidentesMasReportados = props => {
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState("");

  const handleIncrementClick = useCallback(info => {
    setShowModal(!showModal);
    setDataModal(info);
  }, []);

  const info = {
    data: props.data.incidentes.top5Incidentes
  };

  const renderListItems = (props, index) => {
    var bandera = props.total === 0 ? true : false;
    var aux = "";
    if (bandera) {
      aux = "disabledbutton";
    }

    return (
      <div
        key={index}
        className={aux + " incidentesMasItem"}
        onClick={() => handleIncrementClick(props)}
      >
        <div className="etapa">
          <p>{props.etapa}</p>
        </div>
        <div className="row lineaMedia mb-2 ocultarMovil">
          <div className="circulo"></div>
        </div>
        <div className="datosIncidente">
          <span>{EtiquetasGenerales.etiqueta_general_incidente}:</span>
          <div>
            <p className="enfasis1">
              <a>{props.inciso}</a>
            </p>
          </div>
          <p>
            <strong>{formatNumber(props.total)}</strong>{" "}
            {EtiquetasGenerales.etiqueta_general_reportes}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="contenido borde">
      <Col xs={12} md={12}>
        <h2>{EtiquetasGenerales.etiqueta_general_incidente_mas_reportado}</h2>

        <p className="ocultarMovil">
          {
            EtiquetasGenerales.etiqueta_general_incidente_mas_reportado_explicacion
          }
        </p>
        <div>
          {info.data.map((info, index) => renderListItems(info, index))}
        </div>
      </Col>

      {/* Modal */}

      <MiModal
        show={showModal}
        onHide={() => setShowModal(false)}
        data={dataModal}
      ></MiModal>
    </div>
  );
};

export default IncidentesMasReportados;

function MiModal(props) {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalIncidentes"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small>Etapa de la Jornada Electoral:</small>
            <p className="enfasis1">{props.data.etapa}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Incidente: <span className="enfasis2">{props.data.inciso}</span>
          </p>
          <p>{props.data.descripcion}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}

import React from 'react';
import MenuResponsivo from './MenuResponsivo';

//Constants
import { EtiquetasFooter } from '../../Constants/ConstantesGlobales';

const Footer = _ =>{
    return (
      <div>
        <footer className="footer">
          <div className="footerFondoUno">
            <p>
              {EtiquetasFooter.titulo_footer_uno}
            </p>
          </div>
          <div className="footerFondoDos">
            <p>
              {EtiquetasFooter.titulo_footer_dos}
            </p>
            <p>
              {EtiquetasFooter.titulo_footer_tres}
            </p>
            <p>
            <span className="textoColorVerde">Mé</span>
            <span className="textoColorBlanco">xi</span>
            <span className="textoColorRojo">co</span>
              {EtiquetasFooter.titulo_footer_cuatro} {EtiquetasFooter.titulo_footer_version}
            </p>
            <p>
            <span className="footerFondoVersion">{EtiquetasFooter.titulo_footer_revision} {EtiquetasFooter.titulo_footer_rev_fecha_hora}</span>
            </p>
          </div>
        </footer>
        <div className="container">
          <MenuResponsivo />
        </div>
      </div>
    );
}

export default Footer;